const FILTER_DATE_TIME_FORMAT = 'yyyy-MM-dd 00:00:00';
const FILTER_DATE_FORMAT = 'yyyy-MM-dd';
const FILTER_DATE_FORMAT_VIEW = 'dd.MM.yyyy';
const FILTER_DATE_MONTH_YEAR = 'MMMM yyyy';
const FILTER_DATE_SHORT_MONTH_YEAR = 'MM.yyyy';

export {
  FILTER_DATE_FORMAT,
  FILTER_DATE_FORMAT_VIEW,
  FILTER_DATE_MONTH_YEAR,
  FILTER_DATE_SHORT_MONTH_YEAR,
  FILTER_DATE_TIME_FORMAT,
};
