import { CButton } from '@coreui/react-pro';
import React, { FC } from 'react';

import styles from './style.module.scss';

type Props = {
  id: number,
  onClick: (id: number) => void,
};

const IdItem: FC<Props> = ({ onClick, id }) => {
  return (
    <CButton
      size={'sm'}
      variant="outline"
      color="dark"
      className={styles.idStyle}
      onClick={():void => onClick(id)}
    >
      { id }
    </CButton>
  );
};

export { IdItem };
