import React, { CSSProperties, FC } from 'react';

type Props = {
  title: string,
  value: string,
  iconSrc: string,
  style?: CSSProperties,
};

const CashOrdersWidget: FC<Props> = ({ style, iconSrc, value, title }) => {

  return (
    <div style={{
      border: '1px solid #cbcbd0',
      borderRadius: '5px',
      marginBottom: '10px',
      minWidth: '210px',
      width: '100%',
      height: '50px',
      display: 'flex',
      justifyItems: 'flex-start',
      alignItems: 'center',
      ...style,
    }}>
      <img
        style={{ height: '70%',objectFit: 'contain', paddingLeft: '10px', paddingRight: '10px' }}
        src={iconSrc}
        alt={'chart up icon'}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', paddingTop: '5px' }}>
        <span style={{ fontWeight: 'bold', marginRight: 15 }}>{title}</span>
        <span style={{ fontSize: 20, fontWeight: 'bold' }}>{value}</span>
      </div>
    </div>
  );
};

export { CashOrdersWidget };
