import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { CashDeskDto } from 'common/types/types';

import { addCashDesk, getCashOrderById, getCashOrdersByCarServiceId, updateCashOrder } from './actions';

type State = {
  cashOrderLoadStatus: DataStatus,
  listCashOrdersUpdateStatus: DataStatus,
  cashOrderUpdateStatus: DataStatus,
  cashOrders: CashDeskDto[],
  cashOrder: CashDeskDto | null,
};

const initialState: State = {
  cashOrderLoadStatus: DataStatus.IDLE,
  cashOrderUpdateStatus: DataStatus.IDLE,
  listCashOrdersUpdateStatus: DataStatus.IDLE,
  cashOrders: [],
  cashOrder: null,
};

const cashDeskSlice = createSlice({
  name: 'cashDesk',
  initialState,
  reducers: {
    clearCashOrder: (state) => {
      state.cashOrder = null;
    },
  },
  extraReducers: (builder) => {
    builder

      //addCashDesk
      .addCase(addCashDesk.pending, (state) => {
        state.cashOrderLoadStatus = DataStatus.PENDING;
      })
      .addCase(addCashDesk.rejected, (state) => {
        state.cashOrderLoadStatus = DataStatus.REJECTED;
      })
      .addCase(addCashDesk.fulfilled, (state, { payload }) => {
        state.cashOrders = [payload, ...state.cashOrders];
        state.cashOrderLoadStatus = DataStatus.FULFILLED;
      })

      //getCashOrdersByCarServiceId
      .addCase(getCashOrdersByCarServiceId.pending, (state) => {
        state.listCashOrdersUpdateStatus = DataStatus.PENDING;
      })
      .addCase(getCashOrdersByCarServiceId.rejected, (state) => {
        state.listCashOrdersUpdateStatus = DataStatus.REJECTED;
        state.cashOrders = [];
      })
      .addCase(getCashOrdersByCarServiceId.fulfilled, (state, { payload }) => {
        state.listCashOrdersUpdateStatus = DataStatus.FULFILLED;
        state.cashOrders = payload;
      })

      //getCashOrderById
      .addCase(getCashOrderById.pending, (state) => {
        state.cashOrderLoadStatus = DataStatus.PENDING;
      })
      .addCase(getCashOrderById.rejected, (state) => {
        state.cashOrderLoadStatus = DataStatus.REJECTED;
        state.cashOrder = null;
      })
      .addCase(getCashOrderById.fulfilled, (state, { payload }) => {
        state.cashOrderLoadStatus = DataStatus.FULFILLED;
        state.cashOrder = payload;
      })

      //updateCashOrder
      .addCase(updateCashOrder.pending, (state) => {
        state.cashOrderUpdateStatus = DataStatus.PENDING;
      })
      .addCase(updateCashOrder.rejected, (state) => {
        state.cashOrderUpdateStatus = DataStatus.REJECTED;
      })
      .addCase(updateCashOrder.fulfilled, (state, { payload }) => {
        state.cashOrders = state.cashOrders.map((it) => {
          if (it.cashOrderId === payload.cashOrderId) {
            return payload;
          }

          return it;
        });
        state.cashOrderUpdateStatus = DataStatus.FULFILLED;
      });
  },
});

const cashDeskReducer = cashDeskSlice.reducer;
const { clearCashOrder } = cashDeskSlice.actions;

export { cashDeskReducer, clearCashOrder };
