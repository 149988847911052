enum EmployeeFinInfoKey {
  TITLE = 'title',
  POSITION = 'position',
  METHOD_CALC_SALARY= 'methodCalcSalary',
  TOTAL_WORK_HOURS = 'totalWorkHours',
  TOTAL_DONE_ORDERS = 'totalDoneOrders',
  TOTAL_DONE_WORKS = 'totalDoneWorks',
  TOTAL_NORM_HOURS = 'totalNormHours',
  SALARY = 'salary',
  SALARY_ACCRUED = 'salaryAccrued',
  BONUS = 'bonus',
  FINES = 'fines',
  TOTAL = 'total',
  PAID_OUT = 'paidOut',
  FOR_PAYMENT = 'forPayment',
  FINE = 'fine',
  PAY_SALARY = 'paySalary',
  FILTERS = 'filters',
  LAST_MONTH = 'lastMonth',
  LAST_WEEK = 'lastWeek',
  TODAY = 'today',
  CURRENT_WEEK = 'currentWeek',
  CURRENT_MONTH = 'currentMonth',
  TOOLTIP_PROFILE_USER = 'tooltipProfileUser',
  BONUS_MODAL_TITLE = 'bonusModalTitle',
  PENALTY_MODAL_TITLE = 'penaltyModalTitle',
  EMPLOYEE = 'employee',
  PERIOD = 'period',
  BONUS_SUM = 'bonusSum',
  PENALTY_SUM = 'penaltySum',
  UNPAID_BALANCE = 'unpaidBalance',
  OVERPAYMENT = 'overpayment',
  ADD_INFO = 'addInfo',
  SUCCESS_CREATE_BENEFIT_MESSAGE = 'successCreateBenefitMessage',
  SUCCESS_CREATE_PENALTY_MESSAGE = 'successCreatePenaltyMessage',
  METHOD_SALARY_WIDGET_TITLE = 'methodSalaryWidgetTitle',
  PERIOD_TEXT = 'periodText',
  RATE = 'rate',
  TOTAL_ORDERS_TEXT = 'totalOrdersText',
  TOTAL_WORKS_TEXT = 'totalWorksText',
  OF_WORK = 'ofWork',
  TOTAL_SPARES_TEXT = 'totalSparesText',
  OF_SPARE_PARTS = 'ofSpareParts',
  TOTAL_HOURS_TEXT = 'totalHoursText',
  HOURLY_RATE_TEXT = 'hourlyRateText',
  SALARY_ACCRUED_HELP = 'salaryAccruedHelp',
  TOTAL_HELP = 'totalHelp',
  UNPAID_BALANCE_HELP = 'unpaidBalanceHelp',
  PAID_HELP = 'paidHelp',
  FOR_PAID_HELP = 'forPaidHelp',
}

export { EmployeeFinInfoKey };
