import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { EmployeeGetItemsResponse, EmployeeHistory, EmployeeItem, EmployeeShortDto } from 'common/types/types';

import { getEmployee, getEmployees, getHistory, getShortEmployeesByCarServiceId } from './actions';

type State = {
  employees: EmployeeGetItemsResponse,
  history: EmployeeHistory,
  currentEmployee?: EmployeeItem,
  dataStatusEmployees: DataStatus,
  dataStatusCurrentEmployee: DataStatus,
  dataStatusHistory: DataStatus,
  shortEmployees: EmployeeShortDto[],
};

const initialState: State = {
  employees: {
    sumPenaltyAll: 0,
    sumFixedRateAll: 0,
    sumHourlyRateAll: 0,
    sumPercentWorksAll: 0,
    sumPercentSparesAll: 0,
    sumBonusAll: 0,
    sumSalaryAll: 0,
    data: [],
  },
  history: {
    totalWorkPeriod: '',
    employeeHistoryList: [],
  },
  currentEmployee: undefined,
  dataStatusEmployees: DataStatus.IDLE,
  dataStatusCurrentEmployee: DataStatus.IDLE,
  dataStatusHistory: DataStatus.IDLE,
  shortEmployees: [],
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    resetCurrentEmployee: (state) => {
      state.currentEmployee = undefined;
      state.dataStatusCurrentEmployee = DataStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // get employees
      .addCase(getEmployees.pending, (state) => {
        state.dataStatusEmployees = DataStatus.PENDING;
      })
      .addCase(getEmployees.rejected, (state) => {
        state.dataStatusEmployees = DataStatus.REJECTED;
        state.employees = initialState.employees;
      })
      .addCase(getEmployees.fulfilled, (state, { payload }) => {
        state.dataStatusEmployees = DataStatus.FULFILLED;
        state.employees = payload;
      })
    // get employee by id
      .addCase(getEmployee.pending, (state) => {
        state.dataStatusCurrentEmployee = DataStatus.PENDING;
      })
      .addCase(getEmployee.rejected, (state) => {
        state.dataStatusCurrentEmployee = DataStatus.REJECTED;
        state.currentEmployee = undefined;
      })
      .addCase(getEmployee.fulfilled, (state, { payload }) => {
        state.dataStatusCurrentEmployee = DataStatus.FULFILLED;
        state.currentEmployee = payload;
      })
    // get history
      .addCase(getHistory.pending, (state) => {
        state.dataStatusHistory = DataStatus.PENDING;
      })
      .addCase(getHistory.rejected, (state) => {
        state.dataStatusHistory = DataStatus.REJECTED;
        state.history = initialState.history;
      })
      .addCase(getHistory.fulfilled, (state, { payload }) => {
        state.dataStatusHistory = DataStatus.FULFILLED;
        state.history = payload;
      })
      // get short employees
      .addCase(getShortEmployeesByCarServiceId.rejected, (state) => {
        state.shortEmployees = [];
      })
      .addCase(getShortEmployeesByCarServiceId.fulfilled, (state, { payload }) => {
        state.shortEmployees = payload;
      });
  },
});

const employeeReducer = employeesSlice.reducer;
const { resetCurrentEmployee } = employeesSlice.actions;

export { employeeReducer, resetCurrentEmployee };
