import { EmployeeFinInfoKey } from 'common/enums/enums';
import { EmployeeItem } from 'common/types/types';

interface SalaryDataItem {
  labelKey: string,
  value: number,
  valueStyle: Record<string, string | number>,
  containerStyle?: Record<string, string | number>,
  helperTextKey?: string,
}

const getSalaryData = (employee?: EmployeeItem): SalaryDataItem[] => [
  {
    labelKey: EmployeeFinInfoKey.SALARY_ACCRUED,
    value: employee?.sumSalaryWithoutBenefit || 0,
    valueStyle: { color: '#f0ae4e' },
    helperTextKey: EmployeeFinInfoKey.SALARY_ACCRUED_HELP,
  },
  {
    labelKey: EmployeeFinInfoKey.BONUS,
    value: employee?.sumBonus || 0,
    valueStyle: { color: '#2eb85c' },
  },
  {
    labelKey: EmployeeFinInfoKey.FINES,
    value: employee?.sumPenalty || 0,
    valueStyle: { color: '#e7536f' },
  },
  {
    labelKey: EmployeeFinInfoKey.TOTAL,
    value: employee?.sumSalary || 0,
    valueStyle: { color: '#f0ae4e' },
    helperTextKey: EmployeeFinInfoKey.TOTAL_HELP,
  },
  {
    labelKey: EmployeeFinInfoKey.UNPAID_BALANCE,
    value: employee?.sumNoPaidPreviousPeriod || 0,
    valueStyle: { color: '#f0ae4e' },
    helperTextKey: EmployeeFinInfoKey.UNPAID_BALANCE_HELP,
  },
  {
    labelKey: EmployeeFinInfoKey.PAID_OUT,
    value: employee?.sumPaid || 0,
    valueStyle: { color: '#418bff' },
    helperTextKey: EmployeeFinInfoKey.PAID_HELP,
  },
  {
    labelKey: employee?.sumNoPaid && employee?.sumNoPaid < 0
      ? EmployeeFinInfoKey.OVERPAYMENT
      : EmployeeFinInfoKey.FOR_PAYMENT,
    value: employee?.sumNoPaid || 0,
    valueStyle: { color: employee?.sumNoPaid && employee?.sumNoPaid < 0 ? '#e7536f' : '#f0ae4e' },
    containerStyle: { alignSelf: 'flex-end', width: '50%' },
    helperTextKey: EmployeeFinInfoKey.FOR_PAID_HELP,
  },
];

export { getSalaryData };
