import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey, CashDeskKey, CashDeskModalKey } from 'common/enums/enums';
import { useAppSelector } from 'hooks/hooks';
import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { CashDeskFormData } from '../type';

type Props = {
  disabled: boolean,
  register: UseFormRegister<CashDeskFormData>,
};

const TypeCashDeskRow: FC<Props> = ({ disabled, register }) => {

  const { t } = useTranslation(AppTranslationKey.CASH_DESK);

  const { cashOrderTypes } = useAppSelector(({ dictionary })=> ({
    cashOrderTypes: dictionary.data?.cashOrderTypes ?? [],
  }));

  const cashDeskTypes =
    cashOrderTypes.length > 0 ?
      [...cashOrderTypes].sort((a, b) => a.listPosition - b.listPosition) : [];

  return (
    <CRow>
      <CFormLabel htmlFor="cashDeskTypeId" className="col-sm-2 col-form-label">
        {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.CASH_DESK_TYPE_LABEL}`)}
      </CFormLabel>
      <CCol sm={10}>
        <CFormSelect
          disabled={disabled}
          {...register('cashDeskTypeId')}
          id="cashDeskTypeId"

        >
          {
            cashDeskTypes.map(({ cashOrderTypeId, cashOrderTypeName }) => (
              <option key={cashOrderTypeId} value={cashOrderTypeId}>
                {cashOrderTypeName}
              </option>
            ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { TypeCashDeskRow };
