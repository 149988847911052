import { cilHistory } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import cn from 'classnames';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeTableRow } from 'common/types/types';
import { HistoryEmployeeModal } from 'components/modals/modals';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import styles from './style.module.scss';

interface IWorkPeriodItemProps {
  item: EmployeeTableRow,
  carServiceId: number,
}

const WorkPeriodItem: FC<IWorkPeriodItemProps> = ({ item, carServiceId }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = (): void => setIsModalOpen(true);
  const onCloseModal = (): void => setIsModalOpen(false);

  return (
    <>
      <span
        className={cn(styles.workPeriodValue, { [styles.dismissal]: item.isDismissal })}
        data-tooltip-id={ `workPeriodItem${ item.id }` }
        onClick={ onOpenModal }
      >
        <CIcon icon={cilHistory}/>
        {`${t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.WORK_PERIOD}`)}:`}
        <span>{item.workPeriod}</span>
      </span>
      <Tooltip
        id={ `workPeriodItem${ item.id }` }
        border="1px solid #d6d8d9"
        place="bottom"
        opacity={0.75}
        className={ styles.tip }
        content={ t(EmployeesProfilesKey.EMPLOYMENT_HISTORY_TOOLTIP) as string }
      />
      <HistoryEmployeeModal
        onClose={ onCloseModal }
        isOpen={ isModalOpen }
        employeeId={ item.id }
        carServiceId={ carServiceId }
      />
    </>
  );
};

export { WorkPeriodItem };
