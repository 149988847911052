import type { FooterItem } from '@coreui/react-pro/src/components/smart-table/types';
import { AppTranslationKey, HistoryEmployeeModalKey } from 'common/enums/enums';
import { t as tt } from 'i18next';

import styles from '../styles.module.scss';

const translate = (key: string): string => {
  return tt(`${ AppTranslationKey.HISTORY_EMPLOYEE_MODAL }:${ key }`);
};

export const getTableFooter = (totalPeriod: string): FooterItem[] => [
  {
    label: '',
  },
  {
    label: '',
  },
  {
    label: `${ translate(HistoryEmployeeModalKey.TOTAL_WORK_PERIOD) }:`,
    _props: { className: styles.totalCell },
  },
  {
    label: totalPeriod,
    _props: { color: 'light' },
  },
];
