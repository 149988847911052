import { EmployeeFinInfoKey, Lang } from 'common/enums/enums';

export const employeeFinInfo = {
  [EmployeeFinInfoKey.TITLE]: {
    [Lang.UK]: 'Інформація по співробітнику #{{id}}',
    [Lang.EN]: 'Information on the employee #{{id}}',
    [Lang.RU]: 'Информация по сотруднику #{{id}}',
  },
  [EmployeeFinInfoKey.POSITION]: {
    [Lang.UK]: 'Посада',
    [Lang.EN]: 'Position',
    [Lang.RU]: 'Должность',
  },
  [EmployeeFinInfoKey.METHOD_CALC_SALARY]: {
    [Lang.UK]: 'Спосіб нарахування ЗП',
    [Lang.EN]: 'Method of calculating salary',
    [Lang.RU]: 'Способ начисления ЗП',
  },
  [EmployeeFinInfoKey.TOTAL_WORK_HOURS]: {
    [Lang.UK]: 'Всього робочих годин',
    [Lang.EN]: 'Total working hours',
    [Lang.RU]: 'Всего рабочих часов',
  },
  [EmployeeFinInfoKey.TOTAL_DONE_ORDERS]: {
    [Lang.UK]: 'Всього виконано наряд-замовлень',
    [Lang.EN]: 'Total work orders fulfilled',
    [Lang.RU]: 'Всего выполнено наряд-заказов',
  },
  [EmployeeFinInfoKey.TOTAL_DONE_WORKS]: {
    [Lang.UK]: 'Всього виконано робіт',
    [Lang.EN]: 'Total work completed',
    [Lang.RU]: 'Всего выполнено работ',
  },
  [EmployeeFinInfoKey.TOTAL_NORM_HOURS]: {
    [Lang.UK]: 'Всього нормо-годин',
    [Lang.EN]: 'Total norma-hours',
    [Lang.RU]: 'Всего нормо-часов',
  },
  [EmployeeFinInfoKey.SALARY]: {
    [Lang.UK]: 'Заробітна плата',
    [Lang.EN]: 'Salary',
    [Lang.RU]: 'Зарплата',
  },
  [EmployeeFinInfoKey.SALARY_ACCRUED]: {
    [Lang.UK]: 'Нараховано ЗП',
    [Lang.EN]: 'Salary accrued',
    [Lang.RU]: 'Начислено ЗП',
  },
  [EmployeeFinInfoKey.BONUS]: {
    [Lang.UK]: 'Премія',
    [Lang.EN]: 'A bonus',
    [Lang.RU]: 'Премия',
  },
  [EmployeeFinInfoKey.FINES]: {
    [Lang.UK]: 'Штрафи',
    [Lang.EN]: 'Fines',
    [Lang.RU]: 'Штрафы',
  },
  [EmployeeFinInfoKey.TOTAL]: {
    [Lang.UK]: 'Всього',
    [Lang.EN]: 'Total',
    [Lang.RU]: 'Всего',
  },
  [EmployeeFinInfoKey.PAID_OUT]: {
    [Lang.UK]: 'Виплачено',
    [Lang.EN]: 'Paid out',
    [Lang.RU]: 'Выплачено',
  },
  [EmployeeFinInfoKey.FOR_PAYMENT]: {
    [Lang.UK]: 'До оплати',
    [Lang.EN]: 'For payment',
    [Lang.RU]: 'К оплате',
  },
  [EmployeeFinInfoKey.UNPAID_BALANCE]: {
    [Lang.UK]: 'Не виплачений залишок',
    [Lang.EN]: 'Unpaid balance',
    [Lang.RU]: 'Невыплаченный остаток',
  },
  [EmployeeFinInfoKey.OVERPAYMENT]: {
    [Lang.UK]: 'Переплата',
    [Lang.EN]: 'Overpayment',
    [Lang.RU]: 'Переплата',
  },
  [EmployeeFinInfoKey.FINE]: {
    [Lang.UK]: 'Штраф',
    [Lang.EN]: 'A fine',
    [Lang.RU]: 'Штраф',
  },
  [EmployeeFinInfoKey.PAY_SALARY]: {
    [Lang.UK]: 'Виплатити',
    [Lang.EN]: 'Pay the salary',
    [Lang.RU]: 'Выплатить',
  },
  [EmployeeFinInfoKey.FILTERS]: {
    [EmployeeFinInfoKey.LAST_MONTH]: {
      [Lang.UK]: 'Минулий місяць',
      [Lang.EN]: 'Last month',
      [Lang.RU]: 'Прошлый месяц',
    },
    [EmployeeFinInfoKey.LAST_WEEK]: {
      [Lang.UK]: 'Минулий тиждень',
      [Lang.EN]: 'Last week',
      [Lang.RU]: 'Прошлая неделя',
    },
    [EmployeeFinInfoKey.TODAY]: {
      [Lang.UK]: 'Сьогодні',
      [Lang.EN]: 'Today',
      [Lang.RU]: 'Сегодня',
    },
    [EmployeeFinInfoKey.CURRENT_WEEK]: {
      [Lang.UK]: 'Поточний тиждень',
      [Lang.EN]: 'Current week',
      [Lang.RU]: 'Текущая неделя',
    },
    [EmployeeFinInfoKey.CURRENT_MONTH]: {
      [Lang.UK]: 'Поточний місяць',
      [Lang.EN]: 'Current month',
      [Lang.RU]: 'Текущий месяц',
    },
  },
  [EmployeeFinInfoKey.TOOLTIP_PROFILE_USER]: {
    [Lang.UK]: 'Переглянути профіль співробітника',
    [Lang.EN]: 'View employee profile',
    [Lang.RU]: 'Просмотреть профиль сотрудника',
  },
  [EmployeeFinInfoKey.BONUS_MODAL_TITLE]: {
    [Lang.UK]: 'Нарахування премії співробітникові',
    [Lang.EN]: 'Charging a bonus for an employee',
    [Lang.RU]: 'Начисление премии сотруднику',
  },
  [EmployeeFinInfoKey.EMPLOYEE]: {
    [Lang.UK]: 'Співробітник',
    [Lang.EN]: 'Employee',
    [Lang.RU]: 'Сотрудник',
  },
  [EmployeeFinInfoKey.PERIOD]: {
    [Lang.UK]: 'Місяць нарахування',
    [Lang.EN]: 'Month of accrual',
    [Lang.RU]: 'Месяц начисления',
  },
  [EmployeeFinInfoKey.BONUS_SUM]: {
    [Lang.UK]: 'Сума премії',
    [Lang.EN]: 'Premium amount',
    [Lang.RU]: 'Сумма премии',
  },
  [EmployeeFinInfoKey.ADD_INFO]: {
    [Lang.UK]: 'Додаткова інформація',
    [Lang.EN]: 'Additional Information',
    [Lang.RU]: 'Дополнительная информация',
  },
  [EmployeeFinInfoKey.SUCCESS_CREATE_BENEFIT_MESSAGE]: {
    [Lang.UK]: 'Премія для співробітника успішно нарахована',
    [Lang.EN]: 'The employee\'s bonus has been successfully credited',
    [Lang.RU]: 'Премия для сотрудника успешно начислена',
  },
  [EmployeeFinInfoKey.PENALTY_MODAL_TITLE]: {
    [Lang.UK]: 'Нарахування штрафу співробітникові',
    [Lang.EN]: 'Charging a fine to an employee',
    [Lang.RU]: 'Начисление штрафа сотруднику',
  },
  [EmployeeFinInfoKey.PENALTY_SUM]: {
    [Lang.UK]: 'Сума штрафу',
    [Lang.EN]: 'The amount of the fine',
    [Lang.RU]: 'Сумма штрафа',
  },
  [EmployeeFinInfoKey.SUCCESS_CREATE_PENALTY_MESSAGE]: {
    [Lang.UK]: 'Штраф для співробітника успішно нараховано',
    [Lang.EN]: 'The fine for the employee has been successfully charged',
    [Lang.RU]: 'Штраф для сотрудника успешно начислен',
  },
  [EmployeeFinInfoKey.METHOD_SALARY_WIDGET_TITLE]: {
    [Lang.UK]: 'Нарахування ЗП відповідно до вибраного способу',
    [Lang.EN]: 'Salary accrual according to the chosen method',
    [Lang.RU]: 'Начисление ЗП в соответствии с выбранным способом',
  },
  [EmployeeFinInfoKey.PERIOD_TEXT]: {
    [Lang.UK]: 'Період',
    [Lang.EN]: 'Period',
    [Lang.RU]: 'Период',
  },
  [EmployeeFinInfoKey.RATE]: {
    [Lang.UK]: 'Ставка',
    [Lang.EN]: 'Rate',
    [Lang.RU]: 'Ставка',
  },
  [EmployeeFinInfoKey.TOTAL_ORDERS_TEXT]: {
    [Lang.UK]: 'Всього виконано наряд-замовлень',
    [Lang.EN]: 'Total work orders fulfilled',
    [Lang.RU]: 'Всего выполнено наряд-заказов',
  },
  [EmployeeFinInfoKey.TOTAL_WORKS_TEXT]: {
    [Lang.UK]: 'Всього виконано робіт',
    [Lang.EN]: 'Total work completed',
    [Lang.RU]: 'Всего выполнено работ',
  },
  [EmployeeFinInfoKey.OF_WORK]: {
    [Lang.UK]: '% від робіт',
    [Lang.EN]: '% of work',
    [Lang.RU]: '% от работ',
  },
  [EmployeeFinInfoKey.TOTAL_SPARES_TEXT]: {
    [Lang.UK]: 'Всього продано запчастин',
    [Lang.EN]: 'Total spare parts sold',
    [Lang.RU]: 'Всего продано запчастей',
  },
  [EmployeeFinInfoKey.OF_SPARE_PARTS]: {
    [Lang.UK]: '% від запчастин',
    [Lang.EN]: '% of spare parts',
    [Lang.RU]: '% от запчастей',
  },
  [EmployeeFinInfoKey.TOTAL_HOURS_TEXT]: {
    [Lang.UK]: 'Всього нормо-годин',
    [Lang.EN]: 'Total norma-hours',
    [Lang.RU]: 'Всего нормо-часов',
  },
  [EmployeeFinInfoKey.HOURLY_RATE_TEXT]: {
    [Lang.UK]: 'Нормо-години',
    [Lang.EN]: 'Norma-hours',
    [Lang.RU]: 'Нормо-часы',
  },
  [EmployeeFinInfoKey.SALARY_ACCRUED_HELP]: {
    [Lang.UK]: 'Сума, нарахована співробітнику за вибраний місяць або період відповідно до вибраного способу ' +
    'нарахування ЗП, без урахування премій та штрафів.',
    [Lang.EN]: 'The amount accrued to the employee for the selected month or period according to the chosen ' +
    'salary calculation method, excluding bonuses and fines.',
    [Lang.RU]: 'Сумма, начисленная сотруднику за выбранный месяц или период в соответствии с выбранным ' +
    'способом начисления зарплаты, без учета премий и штрафов.',
  },
  [EmployeeFinInfoKey.TOTAL_HELP]: {
    [Lang.UK]: '<ul><li><bold>Формула:</bold> всього = нараховано зп + премія – штрафи.</li></ul>' +
    '<p>Сума, яка включає нараховану ЗП з урахуванням премії та штрафів за вибраний місяць або період.</p>',
    [Lang.EN]: '<ul><li><bold>Formula:</bold> total = salary accrued + а bonus – fines.</li></ul>' +
    '<p>The amount includes the accrued salary, bonuses, and fines for the selected month or period.</p>',
    [Lang.RU]: '<ul><li><bold>Формула:</bold> всего = начислено зп + премия – штрафы.</li></ul>' +
    '<p>Сумма, которая включает начисленную зарплату с учетом премии и штрафов за выбранный месяц или период.</p>',
  },
  [EmployeeFinInfoKey.PAID_HELP]: {
    [Lang.UK]: 'Сума, за вибраний місяць або період, яка вже була виплачена співробітнику через касовий ордер ' +
    'і призначена як аванс або заробітна плата.',
    [Lang.EN]: 'The amount for the selected month or period that has already been paid to the employee through ' +
    'a cash order and designated as an advance or salary.',
    [Lang.RU]: 'Сумма за выбранный месяц или период, которая уже была выплачена сотруднику через кассовый ордер ' +
    'и назначена как аванс или заработная плата.',
  },
  [EmployeeFinInfoKey.UNPAID_BALANCE_HELP]: {
    [Lang.UK]: 'Це сума, яка залишилася невиплаченою співробітнику за попередній період.',
    [Lang.EN]: 'This amount remains unpaid to the employee for the previous period.',
    [Lang.RU]: 'Это сумма, которая осталась невыплаченной сотруднику за предыдущий период.',
  },
  [EmployeeFinInfoKey.FOR_PAID_HELP]: {
    [Lang.UK]: '<ul><li><bold>Формула:</bold> до оплати = всього + не виплачений залишок – виплачено.</li></ul>' +
    '<p>Сума, яку ще необхідно виплатити співробітнику, враховуючи нараховану ЗП, премії, штрафи та ' +
    'невиплачений залишок.</p>',
    [Lang.EN]: '<ul><li><bold>Formula:</bold> for payment: = total + unpaid balance – paid out.</li></ul>' +
    '<p>The amount that still needs to be paid to the employee, considering the accrued salary, bonuses, ' +
    'fines, and unpaid balance.</p>',
    [Lang.RU]: '<ul><li><bold>Формула:</bold> к оплате = всего + невыплаченный остаток – выплачено.</li></ul>' +
    '<p>Сумма, которую еще необходимо выплатить сотруднику, учитывая начисленную зарплату, премии, штрафы ' +
    'и невыплаченный остаток.</p>',
  },
};
