import { Lang } from 'common/enums/enums';

interface ILangKeyData {
  [key: string]: Record<Lang, string> | Record<string, Record<Lang, string>>;
}

const getTranslateKeysByLanguage = (
  objectKeys: ILangKeyData,
  lang: Lang,
): Record<string, string | Record<string, string>> | string => {
  if (objectKeys[lang]) {
    return objectKeys[lang];
  }

  const result: Record<string, string> = {};

  Object.keys(objectKeys).forEach((key) => {
    if (typeof objectKeys[key] !== 'string') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[key] = getTranslateKeysByLanguage(objectKeys[key], lang);
    }
  });

  return result;
};

export { getTranslateKeysByLanguage };
