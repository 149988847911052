import { CButton } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { useFilterContext } from 'contexts';
import { format } from 'date-fns';
import { getPeriodCurrentMonth } from 'helpers/helpers';
import { useAppDispatch } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { employeesActions } from 'store/actions';

interface IApplyButtonProps {
  employeeId: number,
  carServiceId: number,
}

const ApplyButton: FC<IApplyButtonProps> = ({ employeeId, carServiceId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.COMMON);
  const { onSaveFilters, getValue } = useFilterContext();

  const onApplyFilter = (): void => {
    const { dateFrom, dateTo } = (
      getValue('rangeDate') || getPeriodCurrentMonth()
    ) as { dateFrom: string, dateTo: string };

    const salaryDateFrom = format(new Date(dateFrom), 'yyyyMMdd');
    const salaryDateTo = format(new Date(dateTo), 'yyyyMMdd');

    onSaveFilters();

    dispatch(employeesActions.getEmployee({ employeeId, carServiceId, salaryDateFrom, salaryDateTo }));
  };

  return (
    <CButton color="primary" onClick={ onApplyFilter }>
      { t('applyButton') }
    </CButton>
  );
};

export { ApplyButton };
