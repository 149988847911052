import { CFormSelect, CSmartPagination, CSmartTable } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { CarsTableRow } from 'common/types/types';
import {
  AppToolTip,
  CarStateNumber,
  ColorItem, NoData,
  NoDataWithAction,
  Spinner,
  ToggleShowDetailsBtn, usePermissionToastContext,
} from 'components/common/common';
import { Tariffs } from 'constants/tariffs';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useCallback, useMemo,usePersistentPagination } from 'hooks/hooks';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { carActions } from 'store/actions';

import { getCarsTableColumn, getCarsTableData, renderCarItemDetails } from './helpers/helpers';
import styles from './style.module.scss';

type Props = {
  activeCarService: number,
  handleViewEditCarClick: (carId: number, readOnly: boolean) => void,
  handleOpenAddCarModal: () => void,
};

const CarTable: FC<Props> = ({ activeCarService, handleViewEditCarClick, handleOpenAddCarModal }) => {
  const { t } = useTranslation([AppTranslationKey.CAR_SERVICE_AUTO, AppTranslationKey.COMMON]);
  const { cars, dataStatus } = useAppSelector(({ car }) =>
    ({ cars: car.carsData, dataStatus: car.dataStatusGetCars }));

  const carsTableColumn = getCarsTableColumn(t('carTableColumns'));
  const tableData = getCarsTableData(cars.cars);
  const [ details, setDetails ] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const { paginationState, handleSizeChange, handlePageChange } = usePersistentPagination(activeCarService);
  const isLoading = dataStatus === DataStatus.PENDING;
  const { onPermissionAction } = usePermissionToastContext();
  const itemsPerPage: number[] = useMemo( () => getItemPerPageOptions(cars.carsCount), [cars.carsCount] );

  const getCars = useCallback((): void => {
    dispatch(carActions.getCars({
      carServiceId: activeCarService,
      size: paginationState[activeCarService]?.perPage || 10,
      page: paginationState[activeCarService]?.activePage - 1 || 0,
    }));
  }, [ paginationState, activeCarService, dispatch, t ]);

  const onItemsPerPageChange = (value: number): void => {
    handleSizeChange(value);
    handlePageChange(1);
  };

  useEffect(() => {
    activeCarService && getCars();

  }, [ activeCarService, getCars ]);

  if (isLoading && cars.cars.length === 0) {
    return <Spinner isOverflow containerHeight={150}/>;
  }

  if (cars.cars.length === 0) {
    return <NoDataWithAction
      title={t('noCarsTitle')}
      message={t('noCarsText')}
      actionName={t('addCarCaptionButton')}
      callback={onPermissionAction(handleOpenAddCarModal, Tariffs.NO_TARIFF)}
    />;
  }

  const renderCarRegNum = (item: CarsTableRow): ReactElement =>
    <td>
      <CarStateNumber
        carStateNum={item.carRegNum.toUpperCase()}
      />
    </td>;

  const toggleDetails = (id: number): void => {
    const position = details.indexOf(id);
    let newDetails = details.slice();

    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [ ...details, id ];
    }
    setDetails(newDetails);
  };

  const getFormatedAutoColumnValue = (value: { carBrandName: string; carModelName: string }): string => {
    const { carBrandName, carModelName } = value;
    const formatedAutoColumnValue = `${carBrandName ?? ''}  ${carModelName ?? ''}`;

    if ( formatedAutoColumnValue.trim().length === 0 ) {
      return '...';
    }

    return formatedAutoColumnValue;

  };

  return (
    <>
      <CSmartTable
        columns={carsTableColumn}
        itemsPerPage={paginationState[activeCarService]?.perPage || 10}
        items={tableData}
        noItemsLabel={
          <NoData
            title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
            message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
          />
        }
        loading={isLoading}
        scopedColumns={{
          auto: (item: CarsTableRow) => (
            <td>
              <span
                className={styles.carName}
                onClick={(): void => handleViewEditCarClick(item.carId, true)}
              >
                {getFormatedAutoColumnValue({
                  carBrandName: item.carBrandName,
                  carModelName: item.carModelName,
                },
                )}
              </span>
            </td>
          ),
          carRegNum: (item: CarsTableRow) => renderCarRegNum(item),
          carColorName: (item: CarsTableRow) => (
            <td>
              <ColorItem colorCode={item.carColorHtmlCode} />
            </td>
          ),
          carInfo: (item: CarsTableRow) => (
            <td style={{ maxWidth: 0 }}>
              <AppToolTip tooltipId={item.carId.toString()} tooltipContent={item.carInfo} >
                <div className={styles.carInfoColumn}>
                  {item.carInfo}
                </div>
              </AppToolTip>
            </td>
          ),
          show_details: (item: CarsTableRow) => (
            <ToggleShowDetailsBtn
              toggleDetails={(): void => toggleDetails(item.carId)}
              isOpen={details.includes(item.carId)}
            />
          ),
          details: (item) =>
            renderCarItemDetails({
              carBodyTypeName: item.carBodyTypeName,
              createYear: item.createYear,
              mileageKm: item.mileageKm,
              carInfo: item.carInfo,
              capacity: item.capacity,
              fuelType: item.fuelType ?? '',
              visible: details.includes(item.carId),
              handleViewEditTaskClick: (readOnly) => {
                setDetails(details.splice(details.indexOf(item.number), 1));
                handleViewEditCarClick(item.carId, readOnly);
              },
            }),
        }}
        tableHeadProps={{
          color: 'light',
        }}
        tableProps={{
          striped: true,
          hover: true,
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CSmartPagination
          pages={cars.totalPages}
          activePage={paginationState[activeCarService]?.activePage ?? 1}
          onActivePageChange={handlePageChange}
        />
        <CFormSelect
          style={{ width: 70, height: '100%' }}
          onChange={(value): void => onItemsPerPageChange(+value.target.value)}
          value={paginationState[activeCarService]?.perPage ?? '10'}
        >
          {itemsPerPage.map((it) => (
            <option key={it} value={it}>
              {it}
            </option>
          ))}
        </CFormSelect>
      </div>
    </>
  );
};

export { CarTable };
