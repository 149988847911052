import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import { PhoneInputValid } from 'components/common/common';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import styles from '../../styles.module.scss';

type Props = {
  register: UseFormRegister<EmployeeFormData>,
  isDisable: boolean,
  onChange: (value: string) => void,
};

const ViberField: FC<Props> = ({ register, isDisable, onChange }) => {
  const { t } = useTranslation([AppTranslationKey.EMPLOYEES_PROFILES, AppTranslationKey.COMMON]);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="viber" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.VIBER }`) }` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <div className={ styles.containerPhoneField }>
          <PhoneInputValid
            {...register('viber.value')}
            disabled={isDisable}
            name="viber.value"
            onChange={onChange}
            feedbackInvalid={t('maxPhoneLengthError', { ns: AppTranslationKey.COMMON })} />
        </div>
      </CCol>
    </CRow>
  );
};

export { ViberField };
