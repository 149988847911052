import { SorterValue } from '@coreui/react-pro/dist/components/smart-table/types';
import { EmployeeItem, SalaryTableRow } from 'common/types/types';
import { compareNumber, compareString } from 'helpers/compare/compare.helper';

const getFullName = ({ firstName, patronymicName, secondName }: EmployeeItem): string => {
  const fullName = `${ secondName || '' } ${ firstName || '' } ${ patronymicName || '' }`;

  return fullName.replace(/^\s+|\s+$/g, '');
};

const getTableData = (data: EmployeeItem[], sorterValue: SorterValue): SalaryTableRow[] => {
  const result: SalaryTableRow[] = data.map((employee) => ({
    id: employee.employeeId,
    fullName: getFullName(employee),
    position: employee.employeePosition.employeePositionName,
    rate: employee.sumFixedRate || 0,
    hourlyRate: employee.sumHourlyRate || 0,
    percentWorks: employee.sumPercentWorks || 0,
    percentSpares: employee.sumPercentSpares || 0,
    bonus: employee.sumBonus || 0,
    penalty: employee.sumPenalty || 0,
    salaryAll: employee.sumSalary || 0,
    _cellProps: { salaryAll: { color: 'light' } },
  }));

  const { column, state } = sorterValue;

  switch (column) {
  case 'position':
  case 'fullName':
    return result.sort((item1, item2) => {
      return compareString(item1[column], item2[column], state as string);
    });
  case 'id':
  case 'rate':
  case 'hourlyRate':
  case 'percentWorks':
  case 'percentSpares':
  case 'bonus':
  case 'penalty':
  case 'salaryAll':
    return result.sort((item1, item2) => {
      return compareNumber(item1[column], item2[column], state as string);
    });
  }

  return result;
};

export { getTableData };
