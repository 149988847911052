import { LocalStorageKey } from 'common/enums/enums';
import { TAnyValue } from 'common/types/types';
import { useEffect, useState } from 'hooks/hooks';
import { storage } from 'services/services';

export interface IUseFilter<T> {
  initialValue: T,
  storageName: LocalStorageKey,
  storageKey: number | string,
}

interface IUseFilterReturn {
  onChange: (name: string, value: TAnyValue) => void,
  onChangeValues: (values: Record<string, TAnyValue>) => void,
  getValue: (name: string) => TAnyValue,
  onSaveFilters: () => void,
  isInit: boolean,
}

const useFilter = <T extends Record<string, TAnyValue>>({
  initialValue,
  storageName,
  storageKey,
}: IUseFilter<T>): IUseFilterReturn => {
  const [filters, setFilters] = useState<T>(initialValue);
  const [isInit, setIsInit] = useState<boolean>(false);

  const onChange = (name: string, value: TAnyValue): void => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeValues = (values: Record<string, TAnyValue>): void => {
    setFilters((prev) => ({ ...prev, ...values }));
  };

  const getValue = (name: string): TAnyValue => filters[name];

  const onSaveFilters = (): void => {
    const savedFilters: Record<number | string, T> = JSON.parse(storage.getItem(storageName) ?? '{}');

    savedFilters[storageKey] = filters;
    storage.setItem(storageName, JSON.stringify(savedFilters));
  };

  useEffect(() => {
    const savedFilters: Record<number | string, T> = JSON.parse(storage.getItem(storageName) ?? '{}');

    if (savedFilters[storageKey]) {
      setFilters(savedFilters[storageKey]);
    } else {
      setFilters(initialValue);
    }

    setIsInit(true);
  }, [storageName, storageKey]);

  return { onChange, getValue, onSaveFilters, onChangeValues, isInit };
};

export { useFilter };
