import 'react-datepicker/dist/react-datepicker.css';

import {
  CCol,
  CForm,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CTimePicker,
} from '@coreui/react-pro';
import {
  AppTranslationKey,
  DangerAlertModalMode,
  DataStatus,
  OrderModalTranslationKey,
  OrderStatus,
} from 'common/enums/enums';
import { EmployeeItem, OrderDto, OrderModalFormData, StatusTask } from 'common/types/types';
import { ClientSparePartsField,TotalField } from 'components/common/common';
import { DangerAlertModal } from 'components/modals/modals';
import { TextArea } from 'components/textarea/text-area';
import uk from 'date-fns/locale/uk';
import {
  useAppDispatch,
  useAppSelector,
  useEffect,
  useMemo,
  useState,
  useWorkOrderModal,
} from 'hooks/hooks';
import { toNumber } from 'lodash';
import React, { FC, ReactElement } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { carActions, taskActions } from 'store/actions';
import { selectCurrentCar } from 'store/car/reducer';
import { removeStashClientSP } from 'store/task/reducer';

import { ModalFooter } from '../components/components';
import { DEFAULT_ORDER_MODAL_PAYLOAD } from './common';
import { CarField, SparePartsField, WorksField } from './components/components';

type Props = {
  isOpen: boolean;
  isReadOnly: boolean;
  isUpdateOrder: boolean;
  onClose: () => void;
  onEditButtonClick: () => void;
  onSubmit: (payload: OrderModalFormData) => void;
  employees?: EmployeeItem[];
  statuses: StatusTask[];
  order: OrderDto | null;
  onOpenCarModal: () => void,
  onOpenListCarsModal: () => void,
  workFieldAction: () => void,
  clientSparePartsFieldAction: () => void,
  activeCarServiceId: number,
};

registerLocale('uk', uk);

const OrderModalForm: FC<Props> = ({
  isOpen,
  isReadOnly,
  isUpdateOrder,
  onClose,
  onEditButtonClick,
  onSubmit,
  statuses,
  order,
  onOpenCarModal,
  onOpenListCarsModal,
  workFieldAction,
  clientSparePartsFieldAction,
  activeCarServiceId,
}) => {

  const dispatch = useAppDispatch();
  const { modifyWorkOrderDataStatus }
    = useAppSelector(({ workOrder }) => ({
      modifyWorkOrderDataStatus: workOrder.modifyWorkOrderDataStatus,
    }));
  const { t, i18n } = useTranslation(AppTranslationKey.ORDER_MODAL);
  const [isDangerAlertModalOpen, setIsDangerAlertModalOpen] = useState<boolean>(false);
  const [dataForConfirm, setDataForConfirm] = useState<OrderModalFormData | null>(null);
  const [isChangesSpareParts, setIsChangesSpareParts] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    reset,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<OrderModalFormData>({
    defaultValues: DEFAULT_ORDER_MODAL_PAYLOAD,
  });

  const watchAutoId = watch('autoId');
  const watchComment = watch('comment');
  const { getWorkOrderModal, toggleWorkOrderModal } = useWorkOrderModal();

  const {
    currentCar,
    modalSelectedCar,
    selectedWorks,
    spareParts,
    stashClientSpareParts,
  } = useAppSelector(({ car, task }) => ({
    currentCar: car.currentCar,
    modalSelectedCar: car.modalSelectedCar,
    selectedWorks: task.selectedWorks,
    spareParts: task.order?.spares ?? [],
    stashClientSpareParts: task.stashClientSpareParts ?? [],
  }));

  const totalSumWorks = useMemo(() => {
    return selectedWorks.map((it) => (parseFloat(it.qty) * parseFloat(it.price)));
  }, [selectedWorks]);

  const totalSumSpareParts = useMemo(() => {
    return spareParts.map((it) => it.count * it.priceOne);
  }, [spareParts]);

  const TotalOrderSum = (): ReactElement => {
    const label = t(OrderModalTranslationKey.TOTAL_SUM_ORDER);

    return <TotalField label={ label } items={ [...totalSumWorks, ...totalSumSpareParts] } />;
  };

  const TotalWorksSum = (): ReactElement => {
    const label = t(OrderModalTranslationKey.TOTAL_SUM_WORKS_OF_ORDER);

    return <TotalField label={ label } items={ totalSumWorks } isSub />;
  };

  const TotalSparePartsSum = (): ReactElement => {
    const label = t(OrderModalTranslationKey.TOTAL_SUM_SPARE_PARTS_OF_ORDER);

    return <TotalField label={ label } items={ totalSumSpareParts } isSub />;
  };

  useEffect(() => {
    if (!currentCar.isSelect && currentCar.carId) {
      setValue('autoId', currentCar.carId);
      dispatch(selectCurrentCar());
    }
  }, [dispatch, setValue, currentCar.carId, currentCar.isSelect]);

  useEffect(() => {
    if (modifyWorkOrderDataStatus === DataStatus.PENDING) setIsChangesSpareParts(true);

    if (
      modifyWorkOrderDataStatus === DataStatus.FULFILLED &&
      isChangesSpareParts &&
      order?.id
    ) {
      setIsChangesSpareParts(false);
      dispatch(taskActions.getOrderById({ orderId: order?.id }));
    }
  }, [modifyWorkOrderDataStatus]);

  useEffect(() => {
    if (watchAutoId && watchAutoId !== -1) {
      dispatch(carActions.getCarById({ carId: watchAutoId }));
    }
  }, [watchAutoId, dispatch]);

  useEffect(() => {
    if (modalSelectedCar) {
      const { carBrandName = '', carModelName = '', carRegNum = '' } = modalSelectedCar;

      setValue('autoName', `${carBrandName ?? ''} ${carModelName ?? ''} (${carRegNum.toUpperCase() || 'XX 0000 XX'})`);
    }
  }, [modalSelectedCar, setValue]);

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setMinutes(0, 0, 0);

    if (order) {
      reset({
        orderId: order.id,
        autoId: order?.car?.carId ?? undefined,
        autoName: `${modalSelectedCar?.carBrandName ?? ''} ${modalSelectedCar?.carModelName ?? ''} (${modalSelectedCar?.carRegNum.toUpperCase() || 'XX 0000 XX'})`,
        carReceiveDate: order?.dateCarReceive ? new Date(order.dateCarReceive) : currentDate,
        carReceiveTime: order?.dateCarReceive ? new Date(order.dateCarReceive) : currentDate,
        problemDescription: order?.problemDescription,
        requestStatusId: order?.status.id,
        comment: order?.comment,
        duties: '',
      });
    } else {
      reset({ ...DEFAULT_ORDER_MODAL_PAYLOAD, autoName: t('noSelectCar') ?? 'no Car Selected' });
    }
  }, [ order, reset, t ]);

  useEffect(() => {
    isOpen || reset(DEFAULT_ORDER_MODAL_PAYLOAD);
  }, [isOpen, reset]);

  useEffect(() => {
    dataForConfirm && setIsDangerAlertModalOpen(true);
  }, [dataForConfirm]);

  const onShowTime = (): void => {
    const timeMinutesContainer = document.querySelector('.js-car-receive-time .time-picker-roll-col:nth-child(2)');

    if (timeMinutesContainer && timeMinutesContainer.children.length > 12) {
      [].forEach.call(timeMinutesContainer.children, (child:HTMLElement, index) => {
        if(index % 15 !== 0 ) {
          child.style.display = 'none';
        }
      });
    }
  };

  const handleDangerAlertClose = (): void => {
    setIsDangerAlertModalOpen(false);
    setDataForConfirm(null);
  };

  const handleOnConfirmCancelOrder = (): void => {
    dataForConfirm && handleSubmitButton({ ...dataForConfirm });
    handleDangerAlertClose();
  };

  const handleSaveButton = (data: OrderModalFormData): void => {

    if (toNumber(data.requestStatusId) === OrderStatus.REJECTED && order) {
      setDataForConfirm(data);
    } else {
      handleSubmitButton(data);
    }
  };

  const handleSubmitButton = (data: OrderModalFormData): void => {
    onSubmit(data);
  };

  return (
    <CModal
      onClose={onClose}
      visible={isOpen}
      backdrop="static"
      size="xl"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>{t('modalTitle')} #{order?.id}</CModalTitle>
      </CModalHeader>
      <CForm className="form-scroll" onSubmit={handleSubmit(handleSaveButton)}>
        <CModalBody>

          <CRow className="mb-3">
            <CFormLabel htmlFor="status" className="col-sm-2 col-form-label">
              {t('statusFieldLabel')}
            </CFormLabel>
            <CCol sm={10}>
              <CFormSelect
                {...register('requestStatusId')}
                disabled={isReadOnly}
                name="requestStatusId"
                id="status"
              >
                {statuses.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          </CRow>

          <CRow className="mb-3">
            <CFormLabel htmlFor="carReceiveDate" className="col-sm-2 col-form-label">
              {t('dateFieldLabel')}
            </CFormLabel>
            <CCol className="react-calendar-wrapper">
              <Controller
                name="carReceiveDate"
                render={({ field }): ReactElement => (
                  <DatePicker
                    disabled={isReadOnly}
                    id="carReceiveDate"
                    selected={field.value}
                    locale={ i18n.language || 'uk' }
                    placeholderText={t('date') ?? ''}
                    onChange={(value): void => field.onChange(value)}
                    dateFormat="dd.MM.yyyy"
                    calendarStartDay={1}
                    required
                  />
                )}
                control={control}/>
            </CCol>
            <CFormLabel htmlFor="carReceiveTime" className="col-sm-2 col-form-label" style={{ textAlign: 'end' }}>
              {t('timeFieldLabel')}
            </CFormLabel>
            <CCol >
              <Controller
                name="carReceiveTime"
                render={({ field }): ReactElement => (
                  <CTimePicker
                    disabled={isReadOnly}
                    id="carReceiveTime"
                    className="js-car-receive-time"
                    inputReadOnly
                    cleaner={false}
                    time={field.value}
                    locale="uk-UA"
                    seconds={false}
                    onShow={onShowTime}
                    placeholder={t('time') ?? ''}
                    required
                    onTimeChange={
                      (timeString, localeTimeString, value): void =>
                        field.onChange(value ?? null )
                    }
                  />
                )}
                control={control}/>
            </CCol>
          </CRow>

          <CarField
            register={ register }
            errors={ errors }
            isReadOnly={ isReadOnly }
            onOpenAddCarModal={ onOpenCarModal }
            onOpenListCarsModal={ onOpenListCarsModal }
            orderId={ order?.id }
          />

          <CRow className="mb-3">
            <CFormLabel htmlFor="problemDescription" className="col-sm-2 col-form-label">
              {t('shallDoFieldLabel')}
            </CFormLabel>
            <CCol sm={10}>
              <WorksField
                order={order}
                action={workFieldAction}
                isDisable={isReadOnly}
                toggleWorkOrderModal={toggleWorkOrderModal}
                totalLabel={ <TotalWorksSum /> }
              />
            </CCol>
          </CRow>

          {
            order?.id && (
              <CRow className="mb-3">
                <CFormLabel className="col-sm-2 col-form-label">
                  {t('spareParts')}
                </CFormLabel>
                <CCol sm={10}>
                  <SparePartsField toggleWorkOrderModal={toggleWorkOrderModal} totalLabel={ <TotalSparePartsSum /> } />
                </CCol>
              </CRow>
            )
          }

          <CRow className="mb-3">
            <CFormLabel className="col-sm-2 col-form-label">
              {t('userSpareParts')}
            </CFormLabel>
            <CCol sm={10}>
              <ClientSparePartsField
                onRemove={(item): void => {
                  order ?
                    item.sparePartId && dispatch(
                      taskActions.removeClientSpareParts({
                        sparePartIdList: [item.sparePartId],
                        orderId: order.id,
                      })) :
                    dispatch(removeStashClientSP(item));
                }}
                clientSpareParts={order ? order?.sparePartsFromClient ?? [] : stashClientSpareParts}
                order={order}
                toggleWorkOrderModal={toggleWorkOrderModal}
                isDisable={isReadOnly}
                action={clientSparePartsFieldAction}
              />
            </CCol>
          </CRow>

          <CRow className="mb-3">
            <CFormLabel htmlFor="comment" className="col-sm-2 col-form-label">
              {t('commentsFieldLabel')}
            </CFormLabel>
            <CCol sm={10}>
              <TextArea<OrderModalFormData>
                id="comment"
                register={ register }
                limitSymbols={ 1024 }
                rows={3}
                nameFiled="comment"
                value={ watchComment }
                disabled={ isReadOnly }
              />
            </CCol>
          </CRow>
        </CModalBody>
        <ModalFooter
          isReadOnly={isReadOnly}
          isUpdateData={isUpdateOrder}
          onEditButtonClick={onEditButtonClick}
          onClose={onClose}
          desc={ <TotalOrderSum /> }
        />
      </CForm>
      { getWorkOrderModal({ activeCarServiceId }) }
      <DangerAlertModal
        mode={ DangerAlertModalMode.CANCEL_ORDER }
        isVisible={isDangerAlertModalOpen}
        onClose={handleDangerAlertClose}
        onConfirm={handleOnConfirmCancelOrder}/>
    </CModal>
  );
};

export { OrderModalForm };
