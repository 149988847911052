import { cilAccountLogout, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CAvatar, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { AppRoute, PersonalRoute } from 'common/enums/enums';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { authActions } from 'store/actions';

import { getShortUsername } from './helpers/helpers';
import styles from './styles.module.scss';

const HeaderDropdown: FC = () => {
  const { t } = useTranslation('mainMenu');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.currentUser);
  const { name = '' } = (user || {});
  const shortUsername = getShortUsername(name || '');
  const onLogOut = (event: React.MouseEvent):void => {
    event.preventDefault();
    dispatch(authActions.logOut());
  };

  const handleProfileClick = (): void => {
    navigate(`${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.PROFILE}`);
  };

  return (
    <>
      <Tooltip
        id="user-profile"
        content={ t('profile') ?? 'Profile' }
        place="bottom-end"
        style={{ background: '#383838', color: '#ffffff' }}
      />
      <CDropdown variant="nav-item" className="list-unstyled" data-tooltip-id="user-profile">
        <CDropdownToggle className="py-0" caret={false}>
          <CAvatar className={ styles.avatarButton } size="md">{shortUsername}</CAvatar>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" >
          <CDropdownItem href="#" onClick={ handleProfileClick }>
            <CIcon icon={cilUser} className="me-2" />
            {t('profile')}
          </CDropdownItem>
          <CDropdownItem href="#" onClick={onLogOut}>
            <CIcon icon={cilAccountLogout} className="me-2" />
            {t('exit')}
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </>
  );
};

export { HeaderDropdown };
