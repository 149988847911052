import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { AppTranslationKey, HistoryEmployeeModalKey } from 'common/enums/enums';
import { useAppDispatch } from 'hooks/hooks';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { employeesActions } from 'store/actions';

import { HistoryTable } from './table/history-table';

interface IHistoryEmployeeModalProps {
  isOpen: boolean,
  employeeId: number,
  carServiceId: number,
  onClose: () => void,
}

const HistoryEmployeeModal: FC<IHistoryEmployeeModalProps> = ({ isOpen,  employeeId, carServiceId, onClose }) => {
  const { t } = useTranslation(AppTranslationKey.HISTORY_EMPLOYEE_MODAL);
  const { t: tCommon } = useTranslation('common');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(employeesActions.getHistory({ employeeId, carServiceId }));
    }
  }, [isOpen]);

  return (
    <CModal
      onClose={ onClose }
      visible={ isOpen }
      backdrop="static"
      size="lg"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>
          { `${ t(HistoryEmployeeModalKey.TITLE) } #${employeeId}` }
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <HistoryTable />
      </CModalBody>
      <CModalFooter>
        <CButton
          color="dark"
          variant = "ghost"
          onClick={ onClose }
        >
          { tCommon('closeBtn') }
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export { HistoryEmployeeModal };
