import { AppTranslationKey, SalaryKey } from 'common/enums/enums';
import { CarServiceTabsLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SalariesList } from './components/salaries-list';

const Salary: FC = () => {
  const { t } = useTranslation(AppTranslationKey.SALARY);

  return (
    <CarServiceTabsLayout
      ContentComponent={ SalariesList }
      title={ t(SalaryKey.TITLE) as string }
    />
  );
};

export { Salary };
