import React, { FC } from 'react';

import { CarStateLeftSide } from './car-state-left-side';
import styles from './styles.module.scss';

type Props = {
  carStateNum: string;
  onClick?: () => void;
};
const CarStateNumber: FC<Props> = ({ carStateNum, onClick }) => {
  return (
    <div className={styles.carStateNumberWrap}>
      <div className={styles.carStateNum}>
        <CarStateLeftSide />
        <span
          className={onClick ? styles.carStateNumRightSideWithActions : styles.carStateNumRightSide}
          onClick={onClick}
        >{carStateNum || 'XX 0000 XX'}
        </span>
      </div>
    </div>
  );
};

export { CarStateNumber };
