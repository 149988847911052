import { CSmartTable } from '@coreui/react-pro';
import { EmployeeHistoryItem } from 'common/types/types';
import { StatusCell } from 'components/employees-profiles/components/employee-table/cells/status-cell';
import { useAppSelector } from 'hooks/hooks';
import React, { FC, ReactElement } from 'react';

import { columns } from './get-table-column.helper';
import { getTableFooter } from './get-table-footer.helper';

const HistoryTable: FC = () => {
  const { totalWorkPeriod, employeeHistoryList } = useAppSelector(({ employees }) => employees.history);

  return (
    <CSmartTable
      columns={ columns() }
      items={ employeeHistoryList || [] }
      tableHeadProps={{ color: 'light' }}
      tableProps={{ striped: false, hover: false, align: 'middle' }}
      pagination={ false }
      itemsPerPage={ 10 }
      footer={ getTableFooter(totalWorkPeriod) }
      scopedColumns={ {
        dismissalDate: (item: EmployeeHistoryItem): ReactElement => (
          item.dismissalDate ? (<td>{ item.dismissalDate }</td>) : (<StatusCell statusId={ 1 } />)
        ),
      } }
    />
  );
};

export { HistoryTable };
