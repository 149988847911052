import { CButton } from '@coreui/react-pro';
import { Item } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, OrderModalTranslationKey } from 'common/enums/enums';
import { orderDtoToBindOrderMap } from 'common/map/order-dto-to-bind-order.map';
import { ClientSpareParts, OrderDto } from 'common/types/types';
import { IdItem, TableField } from 'components/common/common';
import { useAppDispatch } from 'hooks/hooks';
import { isNull } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { workOrderAction } from 'store/actions';
import { addBindOrder } from 'store/work-order/reducer';

import { getClientSparePartsFieldTableColumns } from './helpers/helpers';

type Props = {
  clientSpareParts: ClientSpareParts[],
  order: OrderDto | null,
  isDisable: boolean,
  action: () => void,
  onRemove: (item: ClientSpareParts) => void,
  toggleWorkOrderModal: (isOpen: boolean, readOnly?: boolean) => void,
};

const ClientSparePartsField: FC<Props> = (
  {
    clientSpareParts,
    order = null,
    isDisable,
    action,
    onRemove,
    toggleWorkOrderModal,
  }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation([AppTranslationKey.CAR_SERVICE_ORDER, AppTranslationKey.ORDER_MODAL]);
  const columns = getClientSparePartsFieldTableColumns();

  const isDisabledAddSparePartButton = (): boolean => {
    return isDisable || isNull(order);
  };

  const handleRemoveClick = (item: Item): void => {
    onRemove(item as ClientSpareParts);
  };

  const handleOrderNumberClick = (orderId: number | null): void =>  {

    if (orderId) {
      dispatch(workOrderAction.getWorkOrderById({ orderId: orderId }));
      toggleWorkOrderModal(true);
    }
  };

  const handleAddOrderClick = ():void => {
    order && dispatch(addBindOrder(orderDtoToBindOrderMap(order)));
    toggleWorkOrderModal(true, false);
  };

  return <TableField
    isDisable={isDisable}
    onRemoveClick={handleRemoveClick}
    columns={ columns }
    data={ clientSpareParts.map((it) =>
      ({
        ...it,
        sparePartName: it.sparePartName.split(' ')[0].length > 50 ?
          it.sparePartName.slice(0, 47) + '...' :
          it.sparePartName,
        canRemove: isNull(it.orderId) } ))
    }
    onSelectClick={action}
    noDataMsg={t('orderNoUserSpareParts') ?? 'The order does not have spare parts from the customer'}
    scopedColumns={{
      sparePartName: (item: ClientSpareParts) => (
        <td >
          <div style={{ textOverflow: 'clip' }}>{item.sparePartName}</div>
        </td>
      ),
      orderId: (item: ClientSpareParts) => (
        <td >
          { item.orderId ?
            <IdItem id={item.orderId} onClick={handleOrderNumberClick} /> :
            <CButton
              color={ isDisabledAddSparePartButton() ? 'dark' : 'primary' }
              size="sm"
              className="p-0"
              disabled={isDisabledAddSparePartButton()}
              onClick={(): void => handleAddOrderClick() }
            >
              <span
                className="p-1"
              >
                {t(OrderModalTranslationKey.ADD_NEW_WORK_ORDER, { ns: AppTranslationKey.ORDER_MODAL } )}
              </span>
            </CButton>
          }
        </td>
      ),
    }}
  />;
};

export { ClientSparePartsField };
