import { FiltersData } from 'common/types/types';
import { FILTER_DATE_SHORT_MONTH_YEAR } from 'constants/date-formats';
import { getFormattedDate, getPeriodCurrentMonth } from 'helpers/helpers';

const cashOrderFilterInitialData: FiltersData = {
  period: getFormattedDate(new Date(), FILTER_DATE_SHORT_MONTH_YEAR),
  rangeDate: getPeriodCurrentMonth(),
  isLastWeek: false,
  isToday: false,
  isCurrentWeek: false,
};

export { cashOrderFilterInitialData };
