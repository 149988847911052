import CIcon from '@coreui/icons-react';
import calc from 'assets/img/calc.png';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { useAppSelector } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getMethodSalaryData } from './method-salary-config';
import styles from './styles.module.scss';

const MethodSalaryWidget: FC = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { t: tCommon } = useTranslation(AppTranslationKey.COMMON);
  const employee = useAppSelector(({ employees }) => employees.currentEmployee);
  const data = getMethodSalaryData(employee);

  return (
    <div className={ styles.methodSalaryWidget }>
      <div className={styles.title}>
        <img
          src={ calc }
          alt={ t(EmployeeFinInfoKey.METHOD_SALARY_WIDGET_TITLE) as string }
          className={ styles.icon }
        />
        <span>{ t(EmployeeFinInfoKey.METHOD_SALARY_WIDGET_TITLE) }</span>
      </div>

      {
        data.map(({
          icon,
          labelTextKey,
          labelValue,
          labelUnitKey,
          captionKey,
          value,
          valueUnitKey,
        }) => (
          <div className={styles.subWidgetItem} key={ labelTextKey }>
            <CIcon icon={ icon } className={styles.itemIcon}/>
            <div className={styles.itemContent}>
              <span className={styles.itemLabel}>
                {`${t(labelTextKey)}: ${labelValue}${ labelUnitKey ? ` ${ tCommon(labelUnitKey) }` : '' }`}
              </span>
              {
                captionKey && (
                  <span>
                    {`${t(captionKey)}: `}
                    <span className={styles.itemValue}>
                      {`${value} ${tCommon(valueUnitKey as string)}`}
                    </span>
                  </span>
                )
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};

export { MethodSalaryWidget };
