import { SorterValue } from '@coreui/react-pro/dist/components/smart-table/types';
import { Contact, EmployeeItem, EmployeeSalary, EmployeeTableRow } from 'common/types/types';
import { compareNumber, compareString } from 'helpers/helpers';

const PHONE_ID = 1;
const EMAIL_ID = 2;
const TELEGRAM_ID = 4;
const VIBER_ID = 5;

const PERCENT_WORKS_ID = 1;
const PERCENT_SPARES_ID = 2;
const HOURLY_RATE_ID = 3;
const FIXED_RATE_ID = 4;

const getSalary = (employeeSalaryDataList: EmployeeSalary[], typeId: number): number | null => {
  const typeSalary = (employeeSalaryDataList || []).find((item) => item.salaryType.salaryTypeId === typeId);

  return typeSalary?.salaryTypeValue || null;
};

const getContactData = (employeeContactList: Contact[], id: number): string => {
  const contact = (employeeContactList || []).find((contact) => contact.contactTypeId === id);

  return contact?.contactData || '';
};

const getTableData = (data: EmployeeItem[], sorterValue: SorterValue): EmployeeTableRow[] => {
  const result = data.map(({
    employeeId,
    secondName,
    firstName,
    patronymicName,
    employeeInfo,
    employeePosition: { employeePositionName },
    employeeContactList,
    employeeStatus: { employeeStatusId: statusId, employeeStatusName: statusName },
    birthDate2,
    age,
    registrationDate2,
    employeeSalaryDataList,
    workPeriod,
  }) => ({
    id: employeeId,
    fullName: `${ secondName || '' } ${ firstName || '' } ${ patronymicName || '' }`.replace(/^\s+|\s+$/g, ''),
    info: employeeInfo,
    position: employeePositionName || '-',
    phone: getContactData(employeeContactList, PHONE_ID) || '-',
    email: getContactData(employeeContactList, EMAIL_ID),
    telegram: getContactData(employeeContactList, TELEGRAM_ID),
    viber: getContactData(employeeContactList, VIBER_ID),
    statusId,
    status: statusName,
    birthDate: birthDate2,
    age,
    registrationDate: registrationDate2,
    percentWorks: getSalary(employeeSalaryDataList, PERCENT_WORKS_ID),
    percentSpares: getSalary(employeeSalaryDataList, PERCENT_SPARES_ID),
    hourlyRate: getSalary(employeeSalaryDataList, HOURLY_RATE_ID),
    fixedRate: getSalary(employeeSalaryDataList, FIXED_RATE_ID),
    workPeriod,
    isDismissal: statusId === 5,
    _props: { color: statusId === 5 ? 'danger' : undefined },
  }));

  const { column, state } = sorterValue;

  switch (column) {
  case 'id':
    return result.sort((item1, item2) => {
      return compareNumber(item1[column], item2[column], state as string);
    });
  case 'fullName':
    return result.sort((item1, item2) => {
      return compareString(item1[column], item2[column], state as string);
    });
  case 'position':
    return result.sort((item1, item2) => {
      return compareString(item1[column], item2[column], state as string);
    });
  case 'status':
    return result.sort((item1, item2) => {
      return compareString(item1.status, item2.status, state as string);
    });
  }

  return result;
};

export { getTableData };
