import 'assets/scss/style.scss';
import 'rsuite/dist/rsuite.min.css';

import { DataStatus, LocalStorageKey } from 'common/enums/enums';
import { PermissionToast, Spinner, usePermissionToastContext } from 'components/common/common';
import { useAppDispatch, useAppSelector, useEffect } from 'hooks/hooks';
import { i18next } from 'i18n';
import { locale as uk_UA } from 'i18n/uk-rsuite';
import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { route } from 'route/route';
import { CustomProvider } from 'rsuite';
import en_US from 'rsuite/locales/en_US';
import ru_RU from 'rsuite/locales/ru_RU';
import { storage } from 'services/services';
import { authActions, dictionaryActions, userActions } from 'store/actions';

const router = createBrowserRouter(route);

const locales: Record<string, typeof en_US> = {
  en: en_US,
  ru: ru_RU,
  uk: uk_UA,
};

const App: FC = () => {

  const { currentUser, dataStatus, locale } = useAppSelector(({ auth, user }) => ({
    currentUser: auth.currentUser,
    dataStatus: auth.dataStatus,
    locale: user.user?.locale,
  }));
  const hasCurrentUser = Boolean(currentUser);
  const hasToken = Boolean(storage.getItem(LocalStorageKey.TOKEN));
  const dispatch = useAppDispatch();
  const { isVisible, onHide } = usePermissionToastContext();
  const rsLocale = locales[i18next.language || 'uk'];

  useEffect(() => {

    if (hasToken && !hasCurrentUser) {
      dispatch(authActions.getCurrentUser())
        .unwrap()
        .catch(() => {
          dispatch(authActions.refreshToken({
            refresh_token: storage.getItem(LocalStorageKey.REFRESH_TOKEN) ?? '',
          }));
        });
    }
  }, [ hasCurrentUser, hasToken, dispatch ]);

  useEffect(() => {
    if (hasCurrentUser && currentUser && !locale) {
      dispatch(userActions.getUserById(currentUser.id));
    }
  }, [currentUser, dispatch, hasCurrentUser, locale]);

  useEffect(() => {
    if (hasCurrentUser && currentUser && locale) {
      i18next.changeLanguage(locale).then( () => {
        dispatch(dictionaryActions.getDictionaries(currentUser.id));
      });
    }
  }, [currentUser, dispatch, hasCurrentUser, locale]);

  if (!hasCurrentUser && hasToken && dataStatus !== DataStatus.REJECTED) {
    return <Spinner isOverflow/>;
  }

  return (
    <CustomProvider locale={ rsLocale }>
      <RouterProvider router={router}/>
      <PermissionToast visible={isVisible} onClose={onHide} />
    </CustomProvider>
  );
};

export { App };
