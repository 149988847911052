import { FILTER_DATE_FORMAT, FILTER_DATE_FORMAT_VIEW } from 'constants/date-formats';
import { useFilterContext } from 'contexts';
import format from 'date-fns/format';
import { getPeriodCurrentMonth } from 'helpers/helpers';
import React, { FC } from 'react';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';

const RangeDateField: FC = () => {
  const { onChangeValues, getValue } = useFilterContext();
  const { dateFrom, dateTo } = (
    getValue('rangeDate') || getPeriodCurrentMonth()
  ) as { dateFrom: string, dateTo: string };
  const value = ([new Date(dateFrom), new Date(dateTo)] as DateRange);
  const limitStartYear = (new Date().getFullYear()) - 1969;

  const onChangeDate = (dates: DateRange | null): void => {
    const dateFrom = format((dates && dates.length) ? dates[0] : new Date(), FILTER_DATE_FORMAT);
    const dateTo = format((dates && dates.length > 0) ? dates[1] : new Date(), FILTER_DATE_FORMAT);

    onChangeValues({
      rangeDate: { dateFrom, dateTo },
      isLastWeek: false,
      isToday: false,
      isCurrentWeek: false,
      period: null,
    });
  };

  return (
    <DateRangePicker
      placeholder="dd.mm.yyyy ~ dd.mm.yyyy"
      format={ FILTER_DATE_FORMAT_VIEW }
      value={ value }
      isoWeek
      onChange={ onChangeDate }
      cleanable={ false }
      limitStartYear={ limitStartYear }
      limitEndYear={ 26 }
    />
  );
};

export { RangeDateField };
