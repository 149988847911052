import { CCard, CCardBody, CCollapse, CListGroup, CListGroupItem } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeTableRow, SalaryType } from 'common/types/types';
import { ContactField, TypeContactField } from 'components/common/common';
import { useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemButtons } from './item-buttons';
import styles from './style.module.scss';
import { WorkPeriodItem } from './work-period-item';

const PERCENT_WORKS_ID = 1;
const PERCENT_SPARES_ID = 2;
const HOURLY_RATE_ID = 3;
const FIXED_RATE_ID = 4;

const getSalaryDescription = (salaryTypes: SalaryType[], typeId: number): string => {
  const typeSalary = salaryTypes.find((item) => item.salaryTypeId === typeId);

  return typeSalary?.salaryTypeDescription || '';
};

type Props = {
  item: EmployeeTableRow,
  carServiceId: number,
  visible: boolean,
};

const ItemDetails: FC<Props> = ({ item, carServiceId, visible }) => {
  const { t } = useTranslation([AppTranslationKey.EMPLOYEES_PROFILES, AppTranslationKey.COMMON]);
  const salaryTypes = useAppSelector(({ dictionary }) => dictionary.data?.salaryTypes || []);

  return (
    <CCollapse visible={ visible }>
      <CCard color="light">
        <CCardBody>
          <CListGroup>
            <CListGroupItem className={styles.itemRow}>
              <span className={styles.itemLabel}>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.BIRTHDATE}`)}:
              </span>
              <span className={ styles.itemValue }>{item.birthDate}</span>
              { item.age && <span className={styles.addInfo}>({item.age})</span> }
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.REGISTRATION_DATE}`)}:
              </span>
              <span className={ styles.itemRegistrationDateContainer }>
                <span className={ styles.itemValue }>{item.registrationDate}</span>
                <WorkPeriodItem item={ item } carServiceId={ carServiceId } />
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                { getSalaryDescription(salaryTypes, PERCENT_WORKS_ID) }:
              </span>
              <span className={ styles.itemValue }>
                { item.percentWorks ? `${ item.percentWorks }%` : '0%' }
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                { getSalaryDescription(salaryTypes, PERCENT_SPARES_ID) }:
              </span>
              <span className={ styles.itemValue }>
                { item.percentSpares ? `${ item.percentSpares }%` : '0%' }
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                { getSalaryDescription(salaryTypes, HOURLY_RATE_ID) }:
              </span>
              <span className={ styles.itemValue }>
                {
                  item.hourlyRate
                    ? `${ item.hourlyRate } ${ t('uah', { ns: AppTranslationKey.COMMON }) }`
                    : `0 ${ t('uah', { ns: AppTranslationKey.COMMON }) }`
                }
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                { getSalaryDescription(salaryTypes, FIXED_RATE_ID) }:
              </span>
              <span className={ styles.itemValue }>
                {
                  item.fixedRate
                    ? `${ item.fixedRate } ${ t('uah', { ns: AppTranslationKey.COMMON }) }`
                    : `0 ${ t('uah', { ns: AppTranslationKey.COMMON }) }`
                }
              </span>
            </CListGroupItem>

            <CListGroupItem className={ styles.itemRow }>
              <span className={ styles.itemLabel }>
                {t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.CONTACTS}`)}:
              </span>
              <div className={ styles.itemContactContainer }>
                <ContactField type={ TypeContactField.PHONE } value={ item.phone === '-' ? '' : `+${ item.phone }` } />
                <ContactField type={ TypeContactField.VIBER } value={ item.viber ? `+${ item.viber }` : '' } />
                <ContactField type={ TypeContactField.TELEGRAM } value={ item.telegram } />
                <ContactField type={ TypeContactField.EMAIL } value={ item.email } />
              </div>
            </CListGroupItem>

            <ItemButtons employeeId={ item.id } carServiceId={ carServiceId } />
          </CListGroup>
        </CCardBody>
      </CCard>
    </CCollapse>
  );
};

export { ItemDetails };
