import { CSmartTable } from '@coreui/react-pro';
import { AppTranslationKey, CarWorkTranslationKey, NotificationType } from 'common/enums/enums';
import { CarWorkFav, CarWorkFavModalFormData, CarWorkFavTableRow, CarWorkUpdateFavRequest } from 'common/types/types';
import { AppToolTip, NoData, NoDataWithAction, Spinner, usePermissionToastContext } from 'components/common/common';
import { FavCarWorkModalForm } from 'components/modals/modals';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useAppDispatch, useMemo, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { appActions, carWorkActions } from 'store/actions';

import { getFavCarWorkData, getFavCarWorkTableColumn } from './helpers/helpers';
import styles from './style.module.scss';

type Props = {
  isLoading: boolean;
  favoritesCarWork: CarWorkFav[];
  onAddFavCarWork: () => void;
};
const FavCarWorkTable: FC<Props> = ({ isLoading, favoritesCarWork, onAddFavCarWork }) => {

  const { t } = useTranslation([AppTranslationKey.CAR_WORK, AppTranslationKey.COMMON]);
  const [ isFavCarWorkModalOpen, setIsFavCarWorkModalOpen ] = useState<boolean>(false);
  const [ selectedRaw, setSelectedRaw ] = useState<CarWorkFavTableRow | null>(null);
  const dispatch = useAppDispatch();
  const carWorkTableColumn =
    useMemo(() => getFavCarWorkTableColumn(t(CarWorkTranslationKey.CAR_WORK_TABLE_COLUMNS)), [t]);
  const carWorkTableData = getFavCarWorkData(favoritesCarWork);
  const handleFavCarWorkModalClose = (): void => {
    setIsFavCarWorkModalOpen(false);
    setSelectedRaw(null);
  };
  const { onPermissionAction } = usePermissionToastContext();
  const itemsPerPage: number[] = useMemo( () => getItemPerPageOptions(favoritesCarWork.length), [ favoritesCarWork ] );

  if (isLoading) {
    return <Spinner isOverflow containerHeight={120}/>;
  }

  if (favoritesCarWork.length === 0) {
    return <NoDataWithAction
      actionName={t(CarWorkTranslationKey.ADD_CAR_WORK_CAPTION_BUTTON)}
      title={t(CarWorkTranslationKey.NO_CAR_WORK_TITLE)}
      message={t(CarWorkTranslationKey.NO_CAR_WORK_TEXT)}
      callback={onPermissionAction(onAddFavCarWork)}
    />;
  }

  const handleSubmitFavCarWorkModalForm = (payload: CarWorkFavModalFormData): void => {
    const favCarWork: CarWorkUpdateFavRequest = {
      carServiceId: payload.carServiceId,
      serviceWorkId: payload.serviceWorkId,
      serviceWorkCost: payload.serviceWorkCost,
      serviceWorkInfo: payload.serviceWorkInfo,
      serviceWorkTypeId: payload.serviceWorkTypeId,
      requiredTimeMinutes: payload.requiredTimeMinutes,
    };
    dispatch(carWorkActions.updateFavoritesCarWork(favCarWork))
      .unwrap()
      .then(() => {
        dispatch(appActions.notify(
          { type: NotificationType.SUCCESS, message: t(CarWorkTranslationKey.UPDATE_SUCCESS) },
        ));
        handleFavCarWorkModalClose();
      });
  };

  return (
    <>
      <CSmartTable
        columns={carWorkTableColumn}
        columnSorter
        columnFilter
        sorterValue={{ column: 'carWorkName', state: 'asc' }}
        items={carWorkTableData}
        noItemsLabel={
          <NoData
            title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
            message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
          />
        }
        itemsPerPageSelect
        itemsPerPageOptions={itemsPerPage}
        itemsPerPageLabel={t(CarWorkTranslationKey.ITEMS_PER_PAGE_LABEL) ?? 'Елементів на сторінці'}
        pagination
        scopedColumns={{
          info: (item: CarWorkFavTableRow) => (
            <td>
              <AppToolTip
                tooltipId={item.serviceWorkId.toString()}
                tooltipContent={item.info}
              >
                <div className={styles.infoColumn}>
                  {item.info}
                </div>
              </AppToolTip>
            </td>
          ),
          carWorkName: (item: CarWorkFavTableRow) => (
            <td>
              <div
                onClick={(): void => {
                  setSelectedRaw(item);
                  setIsFavCarWorkModalOpen(true);
                }}
              >
                <span className={styles.carWorkName}>{item.carWorkName}</span>
              </div>
            </td>
          ),
        }}
        tableHeadProps={{
          color: 'light',
        }}
        tableProps={{
          striped: true,
          hover: true,
        }}
      />
      <FavCarWorkModalForm
        isOpen={isFavCarWorkModalOpen}
        onCloseModal={handleFavCarWorkModalClose}
        favCarWork={selectedRaw}
        onSubmit={handleSubmitFavCarWorkModalForm}
      />
    </>
  );
};

export { FavCarWorkTable };
