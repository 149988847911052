enum WorkOrderModalTranslationKey {
  TITLE = 'title',
  STATUS_LABEL = 'statusLabel',
  ORDER = 'order',
  DATE = 'date',
  TIME = 'time',
  NO_DATA_MSG = 'noDataMsg',
  SELECT_BTN_CAPTION = 'selectBtnCaption',
  REQUIRED = 'required',
  WORK_NAME_TABLE_FIELD = 'workNameTableField',
  QTY_TABLE_FIELD = 'qtyTableField',
  COST_TABLE_FIELD = 'costTableField',
  SUM_TABLE_FIELD = 'sumTableField',
  SPARE_PART_NAME_TABLE_FIELD = 'sparePartNameTableField',
  NO_BIND_ORDER_ERROR_MSG = 'noBindOrderErrorMsg',
  EMPLOYEE = 'employee',
}

export { WorkOrderModalTranslationKey };
