import { FILTER_DATE_MONTH_YEAR, FILTER_DATE_SHORT_MONTH_YEAR } from 'constants/date-formats';
import { useFilterContext } from 'contexts';
import { parse } from 'date-fns';
import { getFormattedDate, getPeriodByMonth } from 'helpers/helpers';
import { useEffect, useState } from 'hooks/hooks';
import { FC } from 'react';
import DatePicker from 'rsuite/DatePicker';

import styles from './styles.module.scss';

const FilterPanel: FC = () => {
  const { onChangeValues, getValue, onSaveFilters } = useFilterContext();
  const period = getValue('period') as string;
  const value = period ? parse(period, FILTER_DATE_SHORT_MONTH_YEAR, new Date()) : null;
  const [isChange, setIsChange] = useState(false);

  const onChangePeriod = (value: Date | null): void => {
    onChangeValues({
      rangeDate: getPeriodByMonth(value || new Date()),
      isLastWeek: false,
      isToday: false,
      isCurrentWeek: false,
      period: getFormattedDate(value || new Date(), FILTER_DATE_SHORT_MONTH_YEAR),
    });
    setIsChange(true);
  };

  useEffect(() => {
    if (isChange) {
      onSaveFilters();
      setIsChange(false);
    }
  }, [period]);

  return (
    <div className={ styles.dataPickerContainer } >
      <DatePicker
        format={ FILTER_DATE_MONTH_YEAR }
        oneTap
        placement="bottomEnd"
        editable={ false }
        cleanable={ false }
        onChange={ onChangePeriod }
        value={ value }
      />
    </div>
  );
};

export { FilterPanel };
