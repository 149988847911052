import { cilCash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react-pro';
import { AppTranslationKey, EmployeeFinInfoKey, NotificationType } from 'common/enums/enums';
import { Icon } from 'components/common/icon/icon';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { appActions } from 'store/actions';

import { BonusButton } from './buttons/bonus-button';
import { PenaltyButton } from './buttons/penalty-button';
import { getSalaryData } from './salary-config';
import styles from './styles.module.scss';

interface ISalaryWidgetProps {
  onLoadEmployee: () => void,
}

const SalaryWidget: FC<ISalaryWidgetProps> = ({ onLoadEmployee }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { t: tCommon } = useTranslation(AppTranslationKey.COMMON);
  const employee = useAppSelector(({ employees }) => employees.currentEmployee);
  const dispatch = useAppDispatch();
  const salaryData = getSalaryData(employee);

  const handlePaySalaryClick = (): void => {
    dispatch(
      appActions.notify({
        type: NotificationType.INFO,
        message: t('comingSoon', { ns: AppTranslationKey.COMMON }),
      }),
    );
  };

  return (
    <div className={styles.salaryWidget}>
      <div className={styles.title}>
        <CIcon icon={cilCash} className={styles.icon}/>
        {t(EmployeeFinInfoKey.SALARY)}
      </div>
      <div className={ styles.itemsContainer }>
        {
          salaryData.map(({
            labelKey,
            value,
            valueStyle,
            containerStyle,
            helperTextKey,
          }) => (
            <div className={styles.itemWidget} key={ labelKey } style={ containerStyle }>
              <div className={styles.itemLabel}>
                <span className={ styles.labelText }>{t(labelKey)}</span>
                {
                  helperTextKey && (
                    <span className={styles.questionIconContainer} data-tooltip-id={labelKey}>
                      <Icon name="question" className={styles.questionIcon}/>
                    </span>
                  )
                }
              </div>
              <span className={styles.itemValue} style={valueStyle}>{`${value} ${tCommon('uah')}`}</span>
              {
                helperTextKey && (
                  <Tooltip
                    id={ labelKey }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    content={ <Trans
                      t={ t }
                      i18nKey={ helperTextKey }
                      components={{ p: <p />, bold: <strong />, ul: <ul />, li: <li /> }}
                    /> }
                    place="bottom"
                    opacity={1}
                    border="1px solid #d6d8d9"
                    style={{ background: '#4c4c4c', color: 'white', opacity: 0.3, zIndex: 5, maxWidth: 450 }}
                  />
                )
              }
            </div>
          ))
        }
      </div>
      <div className={styles.buttonsContainer}>
        <div className={ styles.buttonsSubContainer }>
          <PenaltyButton onLoadEmployee={onLoadEmployee}/>
          <BonusButton onLoadEmployee={onLoadEmployee}/>
        </div>

        <CButton color="primary" onClick={handlePaySalaryClick}>
          {t(EmployeeFinInfoKey.PAY_SALARY)}
        </CButton>
      </div>
    </div>
  );
};

export { SalaryWidget };
