import { CCardBody, CCardTitle, CFormCheck } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { AppToolTip, Icon } from 'components/common/common';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  disabled?: boolean,
};

const WebCard: FC<Props> = ({ disabled }) => {

  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);

  return (
    <div className={styles.cardContainer}>
      <CCardTitle className={styles.cardTitle}>{t('web')}</CCardTitle>
      <CCardBody>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CFormCheck disabled={disabled} label={t('checkWebProfileText')} />
            <AppToolTip
              tooltipId="WebCard"
              tooltipContent={t('webSettingTipText') ?? ''}
              place="bottom"
              styles={{  background: '#4c4c4c', color: 'white', opacity: 0.3 }}
            >
              <Icon name="question" className={styles.icon} />
            </AppToolTip>
          </div>
        </div>
      </CCardBody>
    </div>
  );
};

export { WebCard };
