import { CSmartTable } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { SparePartsDto } from 'common/types/types';
import { NoData, NoDataWithAction, Spinner, usePermissionToastContext } from 'components/common/common';
import { useSparePartModalContext } from 'components/modals/spare-part-modal/context/spare-part-modal-context';
import { Tariffs } from 'constants/tariffs';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useEffect, useMemo } from 'hooks/hooks';
import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sparePartsActions } from 'store/actions';

import { getColumns } from './columns';
import styles from './style.module.scss';

type Props = {
  stockId: number,
};

const SparePartsTable: FC<Props> = ({ stockId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation([AppTranslationKey.SPARE_PARTS, AppTranslationKey.COMMON]);
  const {
    dataStatusGetSpareParts,
    stockSpareParts,
  } = useAppSelector(({ spareParts }) => spareParts);
  const { onOpen: onOpenSparePartModal, setSetting } = useSparePartModalContext();
  const { onPermissionAction } = usePermissionToastContext();
  const itemsPerPage: number[] = useMemo( () => getItemPerPageOptions(stockSpareParts.length), [stockSpareParts] );
  const isLoading = dataStatusGetSpareParts === DataStatus.PENDING;

  const onOpenAddModal = (): void => {
    setSetting({ isReadOnly: false, sparePart: null, stockId });
    onOpenSparePartModal();
  };

  const onOpenReadModal = (sparePart: SparePartsDto): void => {
    setSetting({ isReadOnly: true, sparePart, stockId });
    onOpenSparePartModal();
  };

  useEffect(() => {
    dispatch(sparePartsActions.getAllSparePartsByStoreId({ stockId }));
  }, [stockId, dispatch]);

  if (isLoading && stockSpareParts.length === 0) {
    return <Spinner isOverflow containerHeight={250} />;
  }

  if (stockSpareParts.length === 0 ) {
    return <NoDataWithAction
      title={t('noDataTitle')}
      message={t('noDataText')}
      actionName={t('addButtonTitle')}
      callback={onPermissionAction(onOpenAddModal, Tariffs.NO_TARIFF)}
    />;
  }

  const getRawStyle = (item: SparePartsDto) : CSSProperties => {
    return {
      background: item.valueMin > item.valueCurrent ? 'mistyrose' : 'transparent',
    };
  };

  return (
    <CSmartTable
      columns={ getColumns(t('tableColumns')) }
      columnFilter
      columnSorter
      sorterValue={{ column: 'sparePartsName', state: 'asc' }}
      pagination
      itemsPerPageSelect
      itemsPerPageOptions={itemsPerPage}
      itemsPerPageLabel={t('itemPerPageLabel') ?? 'Елементів на сторінці'}
      items={ stockSpareParts }
      loading={ isLoading }
      noItemsLabel={
        <NoData
          title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
          message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
        />
      }
      scopedColumns={{
        valueMin: (item: SparePartsDto ) =>(
          <td style={ getRawStyle(item) }>
            {item.valueMin}
          </td>
        ),
        sparePartsName: (item: SparePartsDto ) =>(
          <td style={ getRawStyle(item) }>
            <div
              className={styles.sparePartName}
              onClick={(): void => onOpenReadModal(item)}
            >
              {item.sparePartsName}
            </div>
          </td>
        ),
        originalCode: (item: SparePartsDto ) =>(
          <td style={ getRawStyle(item) }>
            {item.originalCode}
          </td>
        ),
        valueCurrent: (item: SparePartsDto ) => (
          <td style={ getRawStyle(item) }>
            <span style={
              item.valueMin > item.valueCurrent ? {
                fontWeight: 'bold',
                color: 'darkred',
                fontSize: '1.1em',
              } : {} }
            >
              {item.valueCurrent}</span>
          </td>
        ),

        priceSell: (item: SparePartsDto ) => (
          <td style={getRawStyle(item)}>
            {item.priceSell}
          </td>
        ),
      }}
      tableHeadProps={{
        color: 'light',
      }}
      tableProps={{
        striped: true,
        hover: true,
        align: 'middle',
      }}
    />
  );
};

export { SparePartsTable };
