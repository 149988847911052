enum SalaryKey {
  TITLE = 'title',
  COLUMNS = 'columns',
  FULL_NAME = 'fullName',
  POSITION = 'position',
  RATE = 'rate',
  NORMA_HOURS = 'normaHours',
  PERCENT_OF_WORK = 'percentOfWork',
  PERCENT_OF_SPARE_PARTS = 'percentOfSpareParts',
  PRIZE = 'prize',
  PENALTIES = 'penalties',
  TOGETHER = 'together',
  TOTAL = 'total',
  TOTAL_EMPLOYEES = 'totalEmployees',
  FIN_INFO_CELL_TOOLTIP = 'finInfoCellTooltip',
}

export { SalaryKey };
