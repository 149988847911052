import { AppTranslationKey, DataStatus, EmployeesProfilesKey, Pages } from 'common/enums/enums';
import { WithCarServicesTabs } from 'common/types/types';
import { NoDataWithAction, Spinner, usePermissionToastContext } from 'components/common/common';
import { EmployeeModal } from 'components/modals/modals';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts';
import { withCarServicesTabs } from 'hoc/hoc';
import { useAppDispatch, useAppSelector, useEffect ,useState } from 'hooks/hooks';
import { t as tt } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { employeesActions } from 'store/actions';

import { EmployeeTable } from './components/employee-table/employee-table';

const EmployeesProfiles: FC<WithCarServicesTabs> = ({ activeCarService }) => {
  const dispatch = useAppDispatch();
  const { id } = activeCarService || {};
  const { t, i18n } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { onPermissionAction } = usePermissionToastContext();
  const {
    employees: { data: employees },
    dataStatusEmployees,
  } = useAppSelector(({ employees }) => employees);
  const isLoading = dataStatusEmployees === DataStatus.PENDING;
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [openRows, setOpenRow] = useState<number[]>([]);

  const onToggleRow = (id: number): void => {
    if(openRows.includes(id)) {
      setOpenRow((prevState) => prevState.filter((item) => item !== id));
    } else {
      setOpenRow((prevState) => [...prevState, id]);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(employeesActions.getEmployees({ carServiceId: id }));
    }
  }, [id, i18n.language]);

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.EMPLOYEES_PROFILES}:${EmployeesProfilesKey.ADD_BUTTON_TITLE}`,
      action: onPermissionAction((): void => setIsOpenModal(true), Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
    };
  }, [i18n.language]);

  if (isLoading || !id) {
    return <Spinner isOverflow containerHeight={150}/>;
  }

  if (employees.length === 0) {
    return (
      <>
        <NoDataWithAction
          title={t(EmployeesProfilesKey.NO_UNIT_TITLE)}
          message={t(EmployeesProfilesKey.NO_UNIT_TEXT)}
          actionName={t(EmployeesProfilesKey.ADD_BUTTON_TITLE)}
          callback={ onPermissionAction((): void => setIsOpenModal(true), Tariffs.NO_TARIFF) }
        />
        {
          isOpenModal && (
            <EmployeeModal
              isOpen={ true }
              carServiceId={ id }
              onClose={ (): void => setIsOpenModal(false) }
            />
          )
        }
      </>
    );
  }

  return (
    <>
      <EmployeeTable activeCarServiceId={ id } onToggleRow={ onToggleRow } openRows={ openRows } />
      {
        isOpenModal && (
          <EmployeeModal
            isOpen={ true }
            carServiceId={ id }
            onClose={ (): void => setIsOpenModal(false) }
          />
        )
      }
    </>
  );
};

export const StorageProfilesWithCarServicesTabs = withCarServicesTabs(
  EmployeesProfiles,
  false,
  Pages.EMPLOYEES,
  () => tt('mainMenu:employeesProfiles') ?? '',
);
