import { ApiPath, CashDeskApiPath, ContentType, HttpMethod } from 'common/enums/enums';
import { CashDeskDto, CashDeskRequestDto, CashDesksGetRequest, CashOrderGetByIdRequest } from 'common/types/types';

import { Http } from '../http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class CashDeskApi {
  #http: Http;

  readonly #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public addCashDesk(payload: CashDeskRequestDto):Promise<CashDeskDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CASH_ORDER}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public getCashOrdersByCarServiceId( payload: CashDesksGetRequest ): Promise<CashDeskDto[]> {

    const { carServiceId, dateFrom, dateTo } = payload;

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CASH_ORDER}${CashDeskApiPath.BY_CAR_SERVICE}/${carServiceId}`,{
        queryString: {
          dateFrom: dateFrom ?? null,
          dateTo: dateTo ?? null,
        },
      },
    );
  }

  public getCashOrderById(payload: CashOrderGetByIdRequest): Promise<CashDeskDto> {
    const { id } = payload;

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CASH_ORDER}/${id}`,
    );
  }

  public updateCashOrder(payload: CashDeskRequestDto):Promise<CashDeskDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.CASH_ORDER}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

}

export { CashDeskApi };
