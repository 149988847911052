import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react-pro';
import { useBreadcrumbsContext } from 'contexts';
import { useParams } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Breadcrumbs: FC = () => {
  const { t } = useTranslation('breadcrumbs');
  const params = useParams();
  const { breadcrumbs } = useBreadcrumbsContext();

  return (
    <CBreadcrumb className="m-0 ms-2">
      {
        breadcrumbs.map(({
          keyNameTranslate,
          isActive,
          path,
        },
        index,
        ) => (
          <CBreadcrumbItem
            active={ isActive }
            key={`${ index }-${ keyNameTranslate }`}
          >
            {
              !path
                ? t(keyNameTranslate, params)
                : (
                  <NavLink to={ path }>
                    { t(keyNameTranslate, params) }
                  </NavLink>
                )
            }
          </CBreadcrumbItem>
        ) )
      }
    </CBreadcrumb>
  );
};

export { Breadcrumbs };
