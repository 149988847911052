enum PersonalRoute {
  ROOT = '/',
  DASHBOARDS = 'dashboards',
  PROFILE = 'profile',
  CAR_SERVICE = 'car-service',
  EDIT_CAR_SERVICE_$CAR_SERVICE_ID = 'car-services-profiles/edit/:carServiceId',
  ADD_CAR_SERVICE = 'car-services-profiles/add',
  CAR_SERVICES_PROFILES = 'car-services-profiles',
  CAR_SERVICES_CUSTOMERS = 'car-services-customers',
  CAR_SERVICES_AUTO = 'car-services-auto',
  CAR_SERVICES_ORDERS = 'car-services-orders',
  CAR_SERVICES_ORDERS_CERTIFICATE = 'car-services-orders/certificate',
  CAR_SERVICES_ORDERS_CERTIFICATE_$ORDER_ID = 'car-services-orders/certificate/:orderId',
  SUPPLIERS = 'suppliers',
  ADD_SUPPLIER = 'suppliers/add',
  EDIT_SUPPLIER = 'suppliers/edit',
  EDIT_SUPPLIER_$SUPPLIER_ID = 'suppliers/edit/:supplierId',
  SPARE_PARTS = 'spare-parts',
  WORK = 'works',
  STORAGE_PROFILES = 'storage-profiles',
  ADD_STORAGE = 'storage-profiles/add',
  EDIT_STORAGE_$STORAGE_ID = 'storage-profiles/edit/:storageId',
  WORK_ORDERS = 'work-orders',
  CASH_DESK = 'cash-desk',
}

export { PersonalRoute };
