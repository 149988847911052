import { CModal, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { Contact, EmployeeFormData } from 'common/types/types';
import { parse } from 'date-fns';
import { useAppDispatch, useAppSelector, useEffect, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { employeesActions } from 'store/actions';

import { getFormattedDate } from '../../../helpers/date/get-formatted-date/get-formatted-date';
import { DEFAULT_EMPLOYEE_MODAL_PAYLOAD } from './form/common';
import { EmployeeForm } from './form/employee-form';

const PHONE_ID = 1;
const EMAIL_ID = 2;
const TELEGRAM_ID = 4;
const VIBER_ID = 5;

const getContactData = (employeeContactList: Contact[], id: number): { value: string, contactId?: number } => {
  const contact = (employeeContactList || []).find((contact) => contact.contactTypeId === id);

  return { value: contact?.contactData || '', contactId: contact?.contactId };
};

const getFormatDate = (dateStr: string | null): string => {
  return dateStr
    ? getFormattedDate(parse(dateStr, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy')
    : getFormattedDate(new Date(), 'dd.MM.yyyy');
};

interface IProps {
  isOpen: boolean,
  carServiceId: number,
  employeeId?: number,
  isDisable?: boolean,
  onEnable?: () => void,
  onClose: () => void,
  onAfterSubmit?: () => void,
}

const EmployeeModal: FC<IProps> = ({
  isOpen,
  carServiceId,
  employeeId,
  isDisable = false,
  onEnable,
  onClose,
  onAfterSubmit,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const currentEmployee = useAppSelector(({ employees }) => employees.currentEmployee);
  const [data, setData] = useState<EmployeeFormData>(DEFAULT_EMPLOYEE_MODAL_PAYLOAD);

  useEffect(() => {
    if (employeeId) {
      dispatch(employeesActions.getEmployee({ employeeId }));
    }
  }, [employeeId]);

  useEffect(() => {
    if (employeeId && currentEmployee) {
      setData({
        firstName: currentEmployee.firstName,
        secondName: currentEmployee.secondName,
        patronymicName: currentEmployee?.patronymicName,
        birthDate: currentEmployee.birthDate ? getFormatDate(currentEmployee.birthDate) : null,
        registrationDate: getFormatDate(currentEmployee.registrationDate),
        positionId: currentEmployee.employeePosition.employeePositionId,
        dismissalDate: currentEmployee.dismissalDate ? getFormatDate(currentEmployee.dismissalDate) : null,
        statusId: currentEmployee.employeeStatus.employeeStatusId,
        salaryTypes: currentEmployee.employeeSalaryDataList.map((item) => ({
          rowId: item.rowId,
          salaryTypeId: item.salaryTypeId,
          salaryTypeValue: item.salaryTypeValue,
        })),
        employeeInfo: currentEmployee.employeeInfo,
        phone: getContactData(currentEmployee.employeeContactList, PHONE_ID),
        email: getContactData(currentEmployee.employeeContactList, EMAIL_ID),
        viber: getContactData(currentEmployee.employeeContactList, VIBER_ID),
        telegram: getContactData(currentEmployee.employeeContactList, TELEGRAM_ID),
      });
    }
  }, [employeeId, currentEmployee]);

  return (
    <CModal
      visible={ isOpen }
      onClose={ onClose }
      backdrop="static"
      size="lg"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>{ `${t(EmployeesProfilesKey.MODAL_TITLE)} #${ employeeId || '' }` }</CModalTitle>
      </CModalHeader>
      <EmployeeForm
        onClose={ onClose }
        carServiceId={ carServiceId }
        employeeId={ employeeId }
        data={ data }
        isDisable={ isDisable }
        onEnable={ onEnable }
        onAfterSubmit={ onAfterSubmit }
      />
    </CModal>
  );
};

export { EmployeeModal };
