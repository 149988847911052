import { CMultiSelect } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IFilterSelectProps {
  values: string[],
  onChange: (callback: (options: string) => boolean) => void,
  placeholder: string,
  isAllSelected?: boolean,
  excludeSelected?: string[],
}

const FilterSelect: FC<IFilterSelectProps> = ({
  values,
  onChange,
  placeholder,
  isAllSelected = false,
  excludeSelected,
}) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const options = [...new Set(values)].sort();

  const onFilter = (selected: { value: string | number }[]): void => {
    const selectedValues = selected.map(({ value }) => value);

    onChange((value) => {
      return Array.isArray(selectedValues) && selectedValues.length > 0
        ? selectedValues.includes(value.toLowerCase())
        : true;
    });
  };

  return (
    <CMultiSelect
      size="sm"
      onChange={ onFilter }
      options={options.map((option) => ({
        value: option.toLowerCase(),
        text: option,
        selected: isAllSelected && !excludeSelected?.includes(option),
      }))}
      selectionType="counter"
      selectAllLabel={ t(EmployeesProfilesKey.SELECT_ALL_OPTIONS) }
      placeholder={ `${ placeholder }...` }
      selectionTypeCounterText={ t(EmployeesProfilesKey.SELECT_COUNTER_TEXT) as string }
    />
  );
};

export { FilterSelect };
