import { cilCalendar, cilClock,cilFeaturedPlaylist, cilMemory, cilTouchApp } from '@coreui/icons';
import { EmployeeFinInfoKey } from 'common/enums/enums';
import { EmployeeItem } from 'common/types/types';

interface IMethodSalaryDataItem {
  icon: string[],
  labelTextKey: string,
  labelValue: string | number,
  labelUnitKey?: string,
  captionKey?: string,
  value?: number,
  valueUnitKey?: string,
}

const getMethodSalaryData = (employee?: EmployeeItem): IMethodSalaryDataItem[] => [
  {
    icon: cilCalendar,
    labelTextKey: EmployeeFinInfoKey.PERIOD_TEXT,
    labelValue: employee?.salaryPeriod || '',
    captionKey: EmployeeFinInfoKey.RATE,
    value: employee?.sumFixedRate,
    valueUnitKey: 'uah',
  },
  {
    icon: cilFeaturedPlaylist,
    labelTextKey: EmployeeFinInfoKey.TOTAL_ORDERS_TEXT,
    labelValue: employee?.totalOrders || 0,
    labelUnitKey: 'pcs',
  },
  {
    icon: cilTouchApp,
    labelTextKey: EmployeeFinInfoKey.TOTAL_WORKS_TEXT,
    labelValue: employee?.totalWorks || 0,
    labelUnitKey: 'pcs',
    captionKey: EmployeeFinInfoKey.OF_WORK,
    value: employee?.sumPercentWorks,
    valueUnitKey: 'uah',
  },
  {
    icon: cilMemory,
    labelTextKey: EmployeeFinInfoKey.TOTAL_SPARES_TEXT,
    labelValue: employee?.totalSpares || 0,
    labelUnitKey: 'pcs',
    captionKey: EmployeeFinInfoKey.OF_SPARE_PARTS,
    value: employee?.sumPercentSpares,
    valueUnitKey: 'uah',
  },
  {
    icon: cilClock,
    labelTextKey: EmployeeFinInfoKey.TOTAL_HOURS_TEXT,
    labelValue: employee?.totalHours || 0,
    captionKey: EmployeeFinInfoKey.HOURLY_RATE_TEXT,
    value: employee?.sumHourlyRate,
    valueUnitKey: 'uah',
  },
];

export { getMethodSalaryData };
