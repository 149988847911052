import { CCard, CCardBody, CCollapse, CListGroup, CListGroupItem, CSmartTable } from '@coreui/react-pro';
import { Item } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey } from 'common/enums/enums';
import { SupplierTableRaw } from 'common/types/types';
import { EditBtn } from 'components/edit-btn/edit-btn';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

type Props = {
  item: SupplierTableRaw,
  visible: boolean,
  onEditClick: () => void,
};

const SupplierItemDetails: FC<Props> = ({ item, visible, onEditClick }) => {
  const { t } = useTranslation(AppTranslationKey.SUPPLIERS);

  return (
    <CCollapse visible={visible}>
      <CCard color="light">
        <CCardBody>
          <CListGroup>
            <CListGroupItem style={{ display: 'flex' }}>
              <p>{t('fieldNameLabel')}:</p>
              <h5 className="ms-3">{item.name}</h5>
            </CListGroupItem>

            <CListGroupItem style={{ display: 'flex' }}>
              <p>{t('fieldStatusLabel')}:</p>
              <h5 className="ms-3">{item.status}</h5>
            </CListGroupItem>

            <CListGroupItem style={{ display: 'flex' }}>
              <p>{t('fieldAddressLabel')}:</p>
              <h5 className="ms-3">{item.address}</h5>
            </CListGroupItem>

            {item.info && <CListGroupItem>
              <div className={styles.info}>
                <CSmartTable
                  columns={[
                    {
                      key: 'info',
                      label: `${t('fieldInfoLabel') ?? 'Info'}:`,
                      filter: false,
                      sorter: false,
                    },
                  ]}
                  items={ [{
                    info: item.info,
                  }] }
                  scopedColumns={{
                    info: (item: Item) => (
                      <td style={{ maxWidth: 0 }}>
                        {item.info}
                      </td>
                    ),
                  }}
                />
              </div>
            </CListGroupItem>}
            <CListGroupItem color="info" className="d-flex justify-content-end align-items-center">
              <EditBtn onClick={ onEditClick } />
            </CListGroupItem>
          </CListGroup>
        </CCardBody>
      </CCard>
    </CCollapse>
  );
};

export { SupplierItemDetails };
