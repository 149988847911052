import { CarServiceProfileFormData } from 'common/types/types';

const DEFAULT_EDIT_CAR_SERVICE_PAYLOAD: CarServiceProfileFormData = {
  id: undefined,
  name: '',
  address: '',
  info: '',
  statusId: 1,
  requisites: null,
  profilePhoto: '',
  profileLogoName: '',
  mainContactPhone: null,
  contactPhone: null,
  contactEmail: null,
  contactTelegram: null,
  contactViber: null,
  contactWeb: null,
};

export { DEFAULT_EDIT_CAR_SERVICE_PAYLOAD };
