import { CCol, CFormLabel, CRow, CTimePicker } from '@coreui/react-pro';
import {
  AppTranslationKey,
  CashDeskKey, CashDeskModalKey,
} from 'common/enums/enums';
import { AppToolTip } from 'components/common/common';
import { onShowTime } from 'helpers/helpers';
import React, { FC, ReactElement } from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CashDeskFormData } from '../type';

type Props = {
  isReadOnly: boolean,
  isCompleted: boolean,
  control:  Control<CashDeskFormData> | undefined,
};

const DatetimeCashDeskRow: FC<Props> = ({ isReadOnly, control, isCompleted }) => {
  const { t, i18n } = useTranslation(AppTranslationKey.CASH_DESK);

  return (
    <CRow className="mb-3">
      <CFormLabel className="col-sm-2 col-form-label">
        {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.DATE}`)}
      </CFormLabel>
      <CCol className="react-calendar-wrapper">
        <Controller
          name="cashDeskDate"
          render={({ field }): ReactElement => (
            <AppToolTip
              tooltipId={'cashDeskDate'}
              isAppTip
              tooltipContent={!isReadOnly && isCompleted ? t(`${CashDeskKey.MODAL}.${CashDeskModalKey.DISABLE_FIELD_MSG}`) ?? '' : ''}
            >
              <DatePicker
                disabled={isReadOnly || isCompleted}
                id="cashDeskDate"
                selected={field.value}
                locale={i18n.language || 'uk'}
                placeholderText={'Date' ?? ''}
                onChange={(value): void => field.onChange(value)}
                dateFormat="dd.MM.yyyy"
                calendarStartDay={1}
                required
              />
            </AppToolTip>
          )}
          control={control}
        />
      </CCol>
      <CFormLabel
        className="col-sm-2 col-form-label"
        style={{ textAlign: 'end' }}
      >
        {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.TIME}`)}
      </CFormLabel>
      <CCol>
        <Controller
          name="cashDeskTime"
          render={({ field }): ReactElement => (
            <AppToolTip
              tooltipId={'cashDeskTime'}
              isAppTip
              tooltipContent={!isReadOnly && isCompleted ? t(`${CashDeskKey.MODAL}.${CashDeskModalKey.DISABLE_FIELD_MSG}`) ?? '' : ''}
            >
              <CTimePicker
                disabled={isReadOnly || isCompleted}
                id="cashDeskTime"
                className="js-car-receive-time"
                inputReadOnly
                cleaner={false}
                time={field.value}
                locale="uk-UA"
                seconds={false}
                onShow={onShowTime}
                placeholder={t('time') ?? ''}
                onTimeChange={(timeString, localeTimeString, value): void =>
                  field.onChange(value ?? null)
                }
              />
            </AppToolTip>
          )}
          control={control}
        />
      </CCol>
    </CRow>
  );
};

export { DatetimeCashDeskRow };
