import { CButton, CCard, CCardBody, CCardHeader, CCol, CContainer, CForm, CListGroup, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { CarServiceProfileFormData, CarServiceRequest } from 'common/types/types';
import { PassLogo } from 'components/car-services-profiles/common/type/type';
import { getValidClasses } from 'helpers/helpers';
import { useAppDispatch, useNavigate } from 'hooks/hooks';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { carServicesActions } from 'store/actions';

import { getListContactsFromFormData } from '../car-service-details/get-list-contacts-from-form-data';
import { getListRequisitesFromFormData } from '../car-service-details/get-list-requisites-from-form-data';
import { ContactCard, DetailsCard, InfoCard } from '../common/common';
import { DEFAULT_EDIT_CAR_SERVICE_PAYLOAD } from '../common/def-payload';
import styles from './styles.module.scss';

const AddCarService: FC = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CarServiceProfileFormData>({
    defaultValues: DEFAULT_EDIT_CAR_SERVICE_PAYLOAD,
  });

  const watchInfo = watch('info');
  const logo = watch('profilePhoto');

  const setLogo = (logo: PassLogo): void => {
    setValue('profilePhoto', logo.base64);
    setValue('profileLogoName', logo.name);
  };

  const confirm = (data: CarServiceProfileFormData): void => {

    const payload: CarServiceRequest = {
      serviceId: data.id,
      address: data.address,
      contacts: getListContactsFromFormData(data),
      info: data.info,
      name: data.name,
      profilePhotoBase64: data.profilePhoto?.split(',')[1],
      profilePhotoName: data.profileLogoName,
      requisites: getListRequisitesFromFormData(data),
      statusId: data.statusId,
    };

    dispatch(carServicesActions.createCarService(payload))
      .unwrap()
      .then(() => navigate(-1));
  };

  return (
    <CContainer fluid>
      <CCard>
        <CCardHeader>
          <h5 className={getValidClasses(styles.headerTitle, 'text-dark')}>
            {t('addCarServiceTitle')}
          </h5>
        </CCardHeader>
        <CCardBody style={{ overflowX: 'auto', minHeight: 150 }}>
          <CForm onSubmit={handleSubmit(confirm)}>
            {/*todo CListGroup not needed here (02.11.2024)*/}
            <CListGroup flush>
              <CRow>
                <CCol xl={6} md={12}>
                  <InfoCard
                    errors={errors}
                    register={register}
                    value={watchInfo}
                    photoUrl={logo}
                    setLogo={setLogo}
                  />
                </CCol>
                <CCol xl={6} md={12}>
                  <ContactCard register={register} setValue={setValue}/>
                  <DetailsCard register={register} />
                </CCol>
              </CRow>
              <CCol className="align-self-end pt-3">
                <CButton
                  className={styles.backButton}
                  variant="outline"
                  onClick={(): void => navigate(-1)}>
                  {t('backCaptionButton')}
                </CButton>

                <CButton className="ps-2" type="submit" color="danger">
                  {t('saveCaptionButton')}
                </CButton>
              </CCol>
            </CListGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </CContainer>
  );
};

export { AddCarService };
