const {
  REACT_APP_API_AUTH_URL,
  REACT_APP_VERSION,
  REACT_APP_BASIC_AUTH_USERNAME,
  REACT_APP_API_URL,
  REACT_APP_BASIC_AUTH_PASSWORD,
  REACT_APP_TARIFF_SUCCESS_URL,
  REACT_APP_TARIFF_FAILURE_URL,
  REACT_APP_TARIFF_REDIRECT_SUCCESS_URL,
  REACT_APP_TARIFF_REDIRECT_FAILURE_URL,
  REACT_APP_SITE_STAT_URL,
  REACT_APP_SITE_STAT_BASE64_URL,
  REACT_APP_IPIFY_URL,
  REACT_APP_SUPPORT_EMAIL,
  REACT_APP_SUPPORT_PHONE,
  REACT_APP_DEV_VERSION,
  REACT_APP_TBOT_URL,
} = process.env;

const ENV = {
  API_AUTH_PATH: REACT_APP_API_AUTH_URL ?? '',
  API_PATH: REACT_APP_API_URL ?? '',
  APP_VERSION: `${REACT_APP_VERSION} ${REACT_APP_DEV_VERSION ?? ''}` ?? '',
  BASIC_AUTH_USERNAME: REACT_APP_BASIC_AUTH_USERNAME ?? '',
  BASIC_AUTH_PASSWORD: REACT_APP_BASIC_AUTH_PASSWORD ?? '',
  TARIFF_SUCCESS_URL: REACT_APP_TARIFF_SUCCESS_URL ?? '',
  TARIFF_FAILURE_URL: REACT_APP_TARIFF_FAILURE_URL ?? '',
  TARIFF_REDIRECT_SUCCESS_URL: REACT_APP_TARIFF_REDIRECT_SUCCESS_URL ?? '',
  TARIFF_REDIRECT_FAILURE_URL: REACT_APP_TARIFF_REDIRECT_FAILURE_URL ?? '',
  IPIFY_URL: REACT_APP_IPIFY_URL ?? '',
  SITE_STAT_URL: REACT_APP_SITE_STAT_URL ?? '',
  SITE_STAT_BASE64_URL: REACT_APP_SITE_STAT_BASE64_URL ?? '',
  SUPPORT_EMAIL: REACT_APP_SUPPORT_EMAIL ?? '',
  SUPPORT_PHONE: REACT_APP_SUPPORT_PHONE ?? '',
  TBOT_URL: REACT_APP_TBOT_URL ?? '',
} as const;

export { ENV };
