import React, { FC, ReactNode } from 'react';
import { PlacesType, Tooltip } from 'react-tooltip';

type Props = {
  tooltipId: string,
  tooltipContent?: string,
  tooltipHtmlContent?: string,
  place?: PlacesType | undefined
  children: ReactNode,
  styles?: React.CSSProperties,
  isAppTip?: boolean,
  classNameContainer?: string,
};

const AppToolTip: FC<Props> = ({
  tooltipId,
  tooltipContent,
  tooltipHtmlContent,
  children,
  place = 'left',
  isAppTip = false,
  styles,
  classNameContainer,
}) => {

  const appTipStyle = {
    background: '#4c4c4c',
    color: 'white',
    opacity: 0.3,
    zIndex: 99999,
    maxWidth: 450,
  };

  const userTipStyle = {
    background: 'white',
    color: '#383838',
    maxWidth: 450,
  };

  return (
    <>
      <Tooltip
        id={tooltipId}
        place={place}
        opacity={1}
        border="1px solid #d6d8d9"
        style={{ ...(isAppTip ? appTipStyle : userTipStyle),...styles }}
      />
      <div
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltipContent}
        data-tooltip-html={tooltipHtmlContent}
        className={ classNameContainer }
      >
        {children}
      </div>
    </>
  );
};

export { AppToolTip };
