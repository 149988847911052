import { UserSignUpRequestDto } from 'common/types/types';

const DEFAULT_SIGN_UP_PAYLOAD: UserSignUpRequestDto = {
  username: '',
  password: '',
  name: '',
  phone: '',
  locale: 'uk',
};

export { DEFAULT_SIGN_UP_PAYLOAD };
