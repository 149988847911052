enum CashDeskKey {
  TITLE = 'title',
  ADD_CASH_DESK_BUTTON_TITLE = 'addCashDeskButtonTitle',
  NO_CASH_ORDERS_TITLE = 'noCashOrdersTitle',
  NO_CASH_ORDERS_TEXT = 'noCashOrdersText',
  MODAL = 'modal',
  SUCCESS_ADDED = 'successAdded',
  CASH_ORDERS_TABLE = 'cashOrdersTable',
  SUCCESS_UPDATED = 'successUpdated',
  WIDGET_TOTAL_MONEY = 'widgetTotalMoney',
  WIDGET_TOTAL_CASH = 'widgetTotalCash',
  WIDGET_TOTAL_IN_ACCOUNT = 'widgetTotalInAccount',
  WIDGET_TOTAL_PROFIT = 'widgetTotalProfit',
  WIDGET_TOTAL_EXPENSE = 'widgetTotalExpenses',
  WIDGET_EXPENSE_PLANNED = 'widgetTotalExpensePlanned',
  WIDGET_NET_PROFIT = 'widgetNetProfit',
}

export { CashDeskKey };
