import { AppTranslationKey, HistoryEmployeeModalKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t as tt } from 'i18next';

const translate = (key: string): string => {
  return tt(`${ AppTranslationKey.HISTORY_EMPLOYEE_MODAL }:${ key }`);
};

const columns = (): TableColumn[] => [
  {
    key: 'recordNumber',
    label: '№',
    sorter: false,
    filter: false,
  },
  {
    key: 'registrationDate',
    label: translate(HistoryEmployeeModalKey.REGISTRATION_DATE),
    sorter: false,
    filter: false,
  },
  {
    key: 'dismissalDate',
    label: translate(HistoryEmployeeModalKey.DISMISSAL_DATE),
    sorter: false,
    filter: false,
  },
  {
    key: 'workPeriod',
    label: translate(HistoryEmployeeModalKey.WORK_PERIOD),
    sorter: false,
    filter: false,
  },
];

export { columns };
