import { CashDeskDto, CashOrdersTableRow } from 'common/types/types';

const getCashOrdersTableData = ( cashOrders: CashDeskDto[]): CashOrdersTableRow[] =>
  cashOrders.map<CashOrdersTableRow>((it) => {
    return {
      id: it.cashOrderId,
      type: it.cashOrderType.cashOrderTypeName,
      assignment: it.cashOrderAssignment.cashOrderAssignmentName,
      info: it.dopInfo ?? '',
      sum: it.orderValue.toString(),
      dateTime: it.createDttm.replace('T', ' ').slice(0, 16),
      paymentMethod: it.paymentMethod.paymentMethodName,
    };
  });

export { getCashOrdersTableData };

