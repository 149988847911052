import { CCol, CFormFeedback, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import cn from 'classnames';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { BonusFormData } from 'common/types/types';
import { useFormContext } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface IBonusFieldProps {
  isPenalty?: boolean,
}

const BonusField: FC<IBonusFieldProps> = ({ isPenalty = false }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { t: tCommon } = useTranslation('common');
  const { register,formState: { errors } } = useFormContext<BonusFormData>();
  const label = isPenalty ? t(EmployeeFinInfoKey.PENALTY_SUM) : t(EmployeeFinInfoKey.BONUS_SUM);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="bonusValue" className="col-sm-3 col-form-label">
        { `${ label }*` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <div className={cn(styles.bonusContainer, { 'is-invalid': Boolean(errors.bonusValue) }) }>
          <CFormInput
            { ...register('bonusValue', {
              required: tCommon('required') ?? 'Name is required',
              validate: (value): boolean | string => !!value || tCommon('required') as string,
            }) }
            id="bonusValue"
            className={ styles.itemInput }
            type="number"
            min={ 0 }
            step={ 0.01 }
            invalid={ Boolean(errors.bonusValue) }
          />
          <span className={ styles.unit }>{ tCommon('uah') }</span>
        </div>
        <CFormFeedback invalid={ Boolean(errors.bonusValue) }>
          { errors.bonusValue?.message || '' }
        </CFormFeedback>
      </CCol>
    </CRow>
  );
};

export { BonusField };
