enum CashOrdersTableKey {
  DATE_TIME = 'dateTime',
  ORDER_TYPE = 'orderType',
  ORDER_CALC_METHOD = 'orderPayMethod',
  SUM = 'sum',
  ASSIGNMENT = 'assignment',
  ORDER = 'order',
  INFO = 'info',
}

export { CashOrdersTableKey };
