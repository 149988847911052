import CIcon from '@coreui/icons-react';
import { IconName } from 'common/types/types';
import { Icon } from 'components/common/common';
import React, { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  title: string,
  icon?: string[],
  iconName?: IconName,
  value: string,
};

const CashDeskWidget: FC<Props> = ({ title, value, icon, iconName }) => {

  return (
    <div className={styles.widget}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{title}</span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {iconName ?
            <Icon name={iconName} className={styles.icon} /> :
            <CIcon className="text-success ms-3" size={'lg'} icon={icon} />}
          <span style={{ marginLeft: 10, fontSize: 20, fontWeight: 'bold' }}>
            {value}
          </span>
        </div>
      </div>
    </div>
  );
};

export { CashDeskWidget };
