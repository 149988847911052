import { AppTranslationKey, CashDeskKey, CashOrdersTableKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getCashOrdersColumns = (): TableColumn[] => {

  return [
    {
      key: 'id',
      label: '#',
      _style: { width: '5%' },
    },
    {
      key: 'dateTime',
      label: t(`${AppTranslationKey.CASH_DESK}:${CashDeskKey.CASH_ORDERS_TABLE}:${CashOrdersTableKey.DATE_TIME}`),
      _style: { width: '15%' },
    },
    {
      key: 'type',
      label: t(`${AppTranslationKey.CASH_DESK}:${CashDeskKey.CASH_ORDERS_TABLE}:${CashOrdersTableKey.ORDER_TYPE}`),
      _style: { width: '10%' },
    },
    {
      key: 'paymentMethod',
      label: t(`${AppTranslationKey.CASH_DESK}:${CashDeskKey.CASH_ORDERS_TABLE}:${CashOrdersTableKey.ORDER_CALC_METHOD}`),
      _style: { width: '15%' },
    },
    {
      key: 'sum',
      label: t(`${AppTranslationKey.CASH_DESK}:${CashDeskKey.CASH_ORDERS_TABLE}:${CashOrdersTableKey.SUM}`),
      _style: { width: '10%' },
    },
    {
      key: 'assignment',
      label: t(`${AppTranslationKey.CASH_DESK}:${CashDeskKey.CASH_ORDERS_TABLE}:${CashOrdersTableKey.ASSIGNMENT}`),
      _style: { width: '20%' },
    },
    {
      key: 'info',
      label: t(`${AppTranslationKey.CASH_DESK}:${CashDeskKey.CASH_ORDERS_TABLE}:${CashOrdersTableKey.INFO}`),
    },
  ];
};

export { getCashOrdersColumns };
