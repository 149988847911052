import { CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { AppTranslationKey, BenefitType, EmployeeFinInfoKey } from 'common/enums/enums';
import { BonusFormData } from 'common/types/types';
import { FormProvider, useAppDispatch, useAppSelector, useEffect, useForm, useMemo } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { benefitAction } from 'store/actions';

import { getDefaultBonusFormPayload } from './common';
import { AddInfoField, BonusField, FullnameField, PeriodField } from './fields';
import { ModalFooter } from './modal-footer';

interface IBonusModalProps {
  isOpen: boolean,
  isPenalty?: boolean,
  onClose: () => void,
  callback: () => void,
}

const BonusModal: FC<IBonusModalProps> = ({ isOpen, isPenalty = false, onClose, callback }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const employee = useAppSelector(({ employees }) => employees.currentEmployee);
  const defaultData = useMemo(() => getDefaultBonusFormPayload(employee), [employee]);
  const title = isPenalty ? t(EmployeeFinInfoKey.PENALTY_MODAL_TITLE) : t(EmployeeFinInfoKey.BONUS_MODAL_TITLE);

  const methods = useForm<BonusFormData>();

  const onCloseModal = (): void => {
    methods.reset(defaultData);
    onClose();
  };

  const onSubmit = async (data: BonusFormData): Promise<void> => {
    return dispatch(benefitAction.createBenefit({
      employeeId: data.id,
      benefitValue: data.bonusValue || 0,
      employeeBenefitTypeId: isPenalty ? BenefitType.PENALTY : BenefitType.BENEFIT,
      benefitMonth: data.period.getMonth() + 1,
      benefitYear: data.period.getFullYear(),
      benefitDescription: data.addInfo,
    })).then(() => callback()).then(() => onClose());
  };

  useEffect(() => {
    if (employee) {
      methods.reset(defaultData);
    }
  }, [employee]);

  return (
    <CModal
      onClose={ onCloseModal }
      visible={ isOpen }
      backdrop="static"
      size="lg"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>
          { `${ title } #${ employee?.employeeId || '' }` }
        </CModalTitle>
      </CModalHeader>
      <FormProvider { ...methods }>
        <CForm onSubmit={ methods.handleSubmit(onSubmit) } className="form-scroll">
          <CModalBody>
            <FullnameField />
            <PeriodField />
            <BonusField isPenalty={ isPenalty } />
            <AddInfoField />
          </CModalBody>
          <ModalFooter onClose={ onCloseModal } />
        </CForm>
      </FormProvider>
    </CModal>
  );
};

export { BonusModal };
