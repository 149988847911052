const onShowTime = (): void => {
  const timeMinutesContainer = document.querySelector('.js-car-receive-time .time-picker-roll-col:nth-child(2)');

  if (timeMinutesContainer && timeMinutesContainer.children.length > 12) {
    [].forEach.call(timeMinutesContainer.children, (child:HTMLElement, index) => {
      if(index % 15 !== 0 ) {
        child.style.display = 'none';
      }
    });
  }
};

export { onShowTime };
