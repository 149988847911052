import { CButton, CCloseButton, CModalFooter } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { getValidClasses } from 'helpers/helpers';
import React, { FC } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';

import { ConfirmFormDataFields } from '../enum/enum';
import { ConfirmFormData } from '../type/type';
import { ConfirmFillFormRow } from './confirm-fill-form-row';
import styles from './styles.module.scss';

type Props = {
  isVisible: boolean,
  onClose: () => void,
  onAllConfirm: () => void,
  searchData: ConfirmFormData,
  onChangeClick: (rowName: ConfirmFormDataFields) => void,
};

const ConfirmFillFormModal: FC<Props> = ({ onAllConfirm, isVisible, onClose, searchData, onChangeClick }) => {

  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <div className={styles.confirmFillFormModal} style={{ display: isVisible ? 'block' : 'none' }} >
      <Draggable
        handle=".header-handle"
        bounds={{ top: -100 }}
      >
        <div className={styles.confirmFillFormModalContent}>
          <div
            className={getValidClasses('header-handle', styles.modalHeader)}
          >
            <b>{t('fillFormModalHeader')}</b>
            <CCloseButton  onClick={onClose} />
          </div>
          <div className={styles.modalBody}>
            <div style={{ marginBottom: 5 }}>
              <p>
                {t('fillFormModalBody')}
              </p>
            </div>
            <ConfirmFillFormRow
              label={ConfirmFormDataFields.REG_NUM}
              value={searchData[ConfirmFormDataFields.REG_NUM]}
              onChangeClick={onChangeClick}
            />
            <ConfirmFillFormRow
              label={ConfirmFormDataFields.VIN_CODE}
              value={searchData[ConfirmFormDataFields.VIN_CODE]}
              onChangeClick={onChangeClick}
            />
            <ConfirmFillFormRow
              label={ConfirmFormDataFields.CAR_BRAND}
              value={searchData[ConfirmFormDataFields.CAR_BRAND]}
              onChangeClick={onChangeClick}
            />
            <ConfirmFillFormRow
              label={ConfirmFormDataFields.MODEL}
              value={searchData[ConfirmFormDataFields.MODEL]}
              onChangeClick={onChangeClick}
            />
            <ConfirmFillFormRow
              label={ConfirmFormDataFields.COLOR}
              value={searchData[ConfirmFormDataFields.COLOR]}
              onChangeClick={onChangeClick}
            />
            <ConfirmFillFormRow
              label={ConfirmFormDataFields.CAR_BODY_TYPE_NAME}
              value={searchData[ConfirmFormDataFields.CAR_BODY_TYPE_NAME]}
              onChangeClick={onChangeClick}
            />
            <ConfirmFillFormRow
              label={ConfirmFormDataFields.CREATE_YEAR}
              value={searchData['createYear']}
              onChangeClick={onChangeClick}
            />
            <ConfirmFillFormRow
              label={ConfirmFormDataFields.FUEL}
              value={searchData['fuelType']}
              onChangeClick={onChangeClick}
            />
            <ConfirmFillFormRow
              label={ConfirmFormDataFields.CAPACITY}
              value={searchData[ConfirmFormDataFields.CAPACITY]}
              onChangeClick={onChangeClick}
            />
          </div>
          <div className={styles.descRow}>
            <div style={{ padding: 5 }}>
              {t('fillFormModalFooter')}
            </div>
          </div>
          <CModalFooter>
            <CButton color={'dark'} onClick={onClose} variant={'ghost'}>
              {t('fillFormBtnClose')}
            </CButton>
            <CButton color={'primary'} variant={'outline'} onClick={onAllConfirm}>
              {t('fillFormBtnConfirmAll')}
            </CButton>
          </CModalFooter>
        </div>
      </Draggable>
    </div>
  );
};

export { ConfirmFillFormModal };
