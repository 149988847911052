import { ENV } from 'common/enums/enums';

import { AuthApi } from './auth-api/auth-api.service';
import { BenefitApiService } from './benefit-api/benefit-api.service';
import { CarApiService } from './car-api/car-api.service';
import { CarServiceApi } from './car-service-api/car-service-api.service';
import { CarWorkApi } from './car-work-api/car-work-api-service';
import { CashDeskApi } from './cash-desk-api/cash-desk-api';
import { ConfidentialTextApi } from './confidential-text/confidential-text-api-service';
import { DictionaryApi } from './dictionary-api/dictionary-api.service';
import { DocumentationApi } from './documentation/documentation-api.service';
import { EmployeesApi } from './employees-api/employees-api.service';
import { ErrorHandlerApi } from './error-handler-api/error-handler-api.service';
import { FeedBackApiService } from './feed-back/feed-back-api.service';
import { Http } from './http/http.service';
import { Notification } from './notification/notification.service';
import { OrderApi } from './order-api/order-api.service';
import { SparePartsApiService } from './spare-parts/spare-parts-api.service';
import { StockApi } from './stock-api/stock-api.service';
import { Storage } from './storage/storage.service';
import { SupplierApiService } from './supplier/supplier-api.service';
import { TariffsApiService } from './tariffs-api/tariffs-api.service';
import { UserApi } from './user/user-api.service';
import { WidgetApi } from './widget/widget-api.service';
import { WorkOrderApi } from './work-order-api/work-order-api.service';

const storage = new Storage({ storage: localStorage });

const http = new Http({ storage, apiRefreshTokenPrefix: ENV.API_AUTH_PATH });

const authApi = new AuthApi({ http, apiPrefix: ENV.API_AUTH_PATH });

const userApi = new UserApi({ http, apiPrefix: ENV.API_PATH });

const carServicesApi = new CarServiceApi({ http, apiPrefix: ENV.API_PATH });

const orderApi = new OrderApi({ http, apiPrefix: ENV.API_PATH });

const notification = new Notification();

const employeesApi = new EmployeesApi({ http, apiPrefix: ENV.API_PATH });

const dictionaryApi = new DictionaryApi({ http, apiPrefix: ENV.API_PATH });

const carApi = new CarApiService({ http, apiPrefix: ENV.API_PATH });

const widgetApi = new WidgetApi({ http, apiPrefix: ENV.API_PATH });

const stockApi = new StockApi({ http, apiPrefix: ENV.API_PATH });

const supplierApi = new SupplierApiService({ http, apiPrefix: ENV.API_PATH });

const carWorkApi = new CarWorkApi({ http, apiPrefix: ENV.API_PATH });

const sparePartsApi = new SparePartsApiService({ http, apiPrefix: ENV.API_PATH });

const feedBackApi = new FeedBackApiService({ http, apiPrefix: ENV.API_AUTH_PATH });

const tariffsApi = new TariffsApiService({ http, apiPrefix: ENV.API_PATH });

const workOrderApi = new WorkOrderApi({ http, apiPrefix: ENV.API_PATH });

const errorHandlerApi = new ErrorHandlerApi({ http, apiPrefix: ENV.API_AUTH_PATH });

const documentationApi = new DocumentationApi({ http, apiPrefix: ENV.API_PATH });

const confidentialTextApi = new ConfidentialTextApi({ http, apiPrefix: ENV.API_AUTH_PATH });

const cashDeskApi = new CashDeskApi({ http, apiPrefix: ENV.API_PATH });

const benefitApi = new BenefitApiService({ http, apiPrefix: ENV.API_PATH });

export {
  authApi,
  benefitApi,
  carApi,
  carServicesApi,
  carWorkApi,
  cashDeskApi,
  confidentialTextApi,
  dictionaryApi,
  documentationApi,
  employeesApi,
  errorHandlerApi,
  feedBackApi,
  notification,
  orderApi,
  sparePartsApi,
  stockApi,
  storage,
  supplierApi,
  tariffsApi,
  userApi,
  widgetApi,
  workOrderApi,
};
