import {
  AppTranslationKey,
  ClientSparePartsTranslationKey,
  DataStatus,
  LocalStorageKey,
  ModalFormMode,
  ModalFormType,
  NotificationType,
  OrderStatus,
  Pages,
} from 'common/enums/enums';
import {
  ClientSpareParts,
  OrderDataRequestDto,
  OrderModalFormData,
  WithCarServicesTabs,
  WorkDto,
} from 'common/types/types';
import {
  NoData,
  Spinner,
  useFilterPanelContext,
  usePermissionToastContext,
} from 'components/common/common';
import {
  CarModalForm,
  CustomerSparePartsModal,
  ListCarsModalForm,
  OrderModalForm,
  SelectWorkToOrderModal,
} from 'components/modals/modals';
import { Page404 } from 'components/page-404/page-404';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts';
import { getFormattedDate } from 'helpers/helpers';
import { withCarServicesTabs } from 'hoc/hoc';
import {
  useAppDispatch,
  useAppSelector,
  useEffect,
  useState,
} from 'hooks/hooks';
import { t as tt } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'services/services';
import { appActions, carActions, taskActions, widgetActions } from 'store/actions';
import { clearModalSelectedCar } from 'store/car/reducer';
import { updateOrderCar } from 'store/car-service/reducer';
import {
  disableReadOnlyModalMode,
  enabledReadOnlyModalMode,
} from 'store/modal/reducer';
import {
  clearOrder,
  clearSelectedWorks,
  stashClientSpareParts,
} from 'store/task/reducer';
import { clearOpenRequests } from 'store/widget/reducer';

import { OrderTable } from './components/components';

const CarServicesOrders: FC<WithCarServicesTabs> = ({ activeCarService }) => {
  const {
    orders,
    userId,
    userCarServices,
    dataStatus,
    getOrdersDataStatus,
    dataStatusAddEditTask,
    modalFormMode,
    order,
    selectedWorks,
    clientSpareParts,
    statuses,
    employees,
    carModalFormMode,
    modalSelectedCar,
  } = useAppSelector(
    ({ carServices, auth, task, employees, modal, dictionary, car }) => ({
      orders: carServices.orders,
      getOrdersDataStatus: carServices.getOrdersDataStatus,
      clientSpareParts: task.stashClientSpareParts,
      userCarServices: carServices.userCarServices,
      dataStatus: carServices.dataStatus,
      userId: auth.currentUser?.id ?? 0,
      dataStatusAddEditTask: task.dataStatusAddEditTask,
      statuses: dictionary.data?.requestStatuses || [],
      employees: employees.employees.data,
      selectedWorks: task.selectedWorks,
      order: task.order,
      modalFormMode:
        modal.modalFormMode?.[ModalFormType.ORDER_MODAL] ??
        ModalFormMode.READONLY_MODE,
      carModalFormMode:
        modal.modalFormMode?.[ModalFormType.CAR_MODAL] ??
        ModalFormMode.READONLY_MODE,
      modalSelectedCar: car.modalSelectedCar,
    }),
  );

  const [isTaskModalOpen, setIsTaskModalOpen] = useState<boolean>(false);
  const [isCarModalOpen, setIsCarModalOpen] = useState<boolean>(false);
  const [isListCarsModalOpen, setIsListCarsModalOpen] =
    useState<boolean>(false);
  const [isSelectWorkOpen, setIsSelectWorkOpen] = useState<boolean>(false);
  const [isClientSparePartsOpen, setIsClientSparePartsOpen] =
    useState<boolean>(false);
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { onPermissionAction } = usePermissionToastContext();

  const { t, i18n } = useTranslation([
    AppTranslationKey.CAR_SERVICE_ORDER,
    AppTranslationKey.CAR_SERVICE,
  ]);

  const dispatch = useAppDispatch();

  const { getListItems, isInit, setIsInit } = useFilterPanelContext();

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.CAR_SERVICE_ORDER}:addOrderCaptionButton`,
      action: onPermissionAction(handleAddOrderClick, Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
      // dispatch(clearCarServiceOrders());
      storage.removeItem(LocalStorageKey.CAR_SERVICE_STATE);
    };
  }, [i18n.language]);

  useEffect(() => {
    dispatch(taskActions.getStatuses());
  }, [dispatch]);

  useEffect(() => {
    if (isInit) {
      getListItems();
      setIsInit(false);
    }
  }, [isInit]);

  useEffect(() => {
    dispatch(widgetActions.getOpenRequests({ userId }));

    return () => {
      dispatch(clearOpenRequests());
    };
  }, [dispatch, userId]);

  const isLoading = dataStatus === DataStatus.PENDING;

  const isOrdersLoading = getOrdersDataStatus === DataStatus.PENDING;
  const isUpdateOrder = dataStatusAddEditTask === DataStatus.PENDING;
  const isCarModalFormReadOnly =
    carModalFormMode === ModalFormMode.READONLY_MODE;
  const isOrderModalFormReadOnly =
    modalFormMode === ModalFormMode.READONLY_MODE;

  useEffect(() => {
    if (!isCarModalFormReadOnly && modalSelectedCar) {
      dispatch(updateOrderCar(modalSelectedCar));
    }
  }, [modalSelectedCar]);

  const handleCloseOrderModal = (): void => {
    dispatch(clearModalSelectedCar());
    dispatch(clearSelectedWorks());
    dispatch(clearOrder());
    setIsTaskModalOpen(false);
  };

  const handleOpenOrderModal = (): void => setIsTaskModalOpen(true);
  const handleOpenListCarsModal = (): void => setIsListCarsModalOpen(true);
  const handleCloseListCarsModal = (): void => setIsListCarsModalOpen(false);
  const handleCloseSelectWorkModal = (): void => setIsSelectWorkOpen(false);
  const handleClientSparePartCloseModal = (): void =>
    setIsClientSparePartsOpen(false);

  const handleCloseCarModalForm = (): void => {
    setIsCarModalOpen(false);
  };

  const handleOpenCarModalForm = (): void => {
    dispatch(disableReadOnlyModalMode(ModalFormType.CAR_MODAL));
    setIsCarModalOpen(true);
  };

  const handleAddOrderClick = (): void => {
    dispatch(disableReadOnlyModalMode(ModalFormType.ORDER_MODAL));
    handleOpenOrderModal();
  };

  const handleViewEditOrderClick = (
    orderId: number,
    readOnly: boolean,
  ): void => {
    dispatch(
      readOnly
        ? enabledReadOnlyModalMode(ModalFormType.ORDER_MODAL)
        : disableReadOnlyModalMode(ModalFormType.ORDER_MODAL),
    );
    dispatch(taskActions.getOrderById({ orderId }))
      .unwrap()
      .then(() => {
        handleOpenOrderModal();
      });
  };

  const handleOrderModify = (orderModalFormData: OrderModalFormData): void => {
    const dateStr = getFormattedDate(
      orderModalFormData.carReceiveDate || new Date(),
      'yyyy-MM-dd',
    );
    const timeStr = getFormattedDate(
      orderModalFormData.carReceiveTime || new Date(),
      'HH:mm:ss',
    );

    const order: OrderDataRequestDto = {
      carId: orderModalFormData.autoId,
      dateCarReceive: `${dateStr}T${timeStr}`,
      requestStatusId: +orderModalFormData.requestStatusId,
      requestSourceId: 2,
      carServiceId: activeCarService?.id,
      problemDescription: orderModalFormData.problemDescription,
      comment: orderModalFormData.comment,
      requestTypeId: 1,
      works: selectedWorks.map((item): WorkDto => {
        return {
          priceOne: parseFloat(item.price),
          serviceWorkId: item.workId,
          serviceWorkName: item.name,
          workCount: parseFloat(item.qty),
          priceTotal: parseFloat(item.sum),
        };
      }),
      sparePartsFromClient: clientSpareParts,
    };

    if (orderModalFormData.orderId) {
      dispatch(
        taskActions.updateOrder({
          ...order,
          requestId: orderModalFormData.orderId,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(widgetActions.getOpenRequests({ userId }));
          dispatch(
            appActions.notify({
              type: NotificationType.SUCCESS,
              message: t('successEditOrderMes'),
            }),
          );
          handleCloseOrderModal();
        });
    } else {
      if (
        order.requestStatusId === OrderStatus.CLOSE &&
        (order?.works ?? []).length > 0
      ) {
        dispatch(
          appActions.notify({
            type: NotificationType.ERROR,
            message: t('openWorkErrorMes'),
          }),
        );
      } else {
        dispatch(taskActions.addTask(order))
          .unwrap()
          .then(() => {
            dispatch(widgetActions.getOpenRequests({ userId }));
            dispatch(
              appActions.notify({
                type: NotificationType.SUCCESS,
                message: t('successAddOrderMes'),
              }),
            );
            handleCloseOrderModal();
          });
      }
    }
  };

  const handleEditButtonClick = (): void => {
    dispatch(disableReadOnlyModalMode(ModalFormType.ORDER_MODAL));
  };

  const handleSelectWorkToOrderSubmit = (): void => {
    //do nothing
  };

  const onSaveClientSpareParts = (
    clientSpareParts: ClientSpareParts[],
  ): void => {
    order
      ? dispatch(
        taskActions.updateClientSPInOrder({
          sparePartFromClientList: clientSpareParts,
          order: order,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(
            appActions.notify({
              type: NotificationType.SUCCESS,
              message: t(ClientSparePartsTranslationKey.SUCCESS_ADDED_SP, {
                ns: AppTranslationKey.CLIENT_SPARE_PARTS,
              }),
            }),
          );
        })
      : dispatch(stashClientSpareParts(clientSpareParts));
    handleClientSparePartCloseModal();
  };

  const handleWorkFieldAction = (): void => setIsSelectWorkOpen(true);
  const handleClientSparePartsFieldAction = (): void =>
    setIsClientSparePartsOpen(true);
  const handleOrderNumberClick = (id: number): void => {
    handleViewEditOrderClick(id, true);
  };
  const handleCarNameClick = (carId: number): void => {
    dispatch( carActions.getCarById({ carId }));
    dispatch(enabledReadOnlyModalMode(ModalFormType.CAR_MODAL));
    setIsCarModalOpen(true);
  };

  if (isLoading) {
    return <Spinner isOverflow containerHeight={250} />;
  }

  if (activeCarService === null) {
    return <Page404 />;
  }

  if (userCarServices.length === 0) {
    return (
      <NoData
        title={t('noCarServiceTitle', { ns: AppTranslationKey.CAR_SERVICE })}
        message={t('noCarServiceText', { ns: AppTranslationKey.CAR_SERVICE })}
      />
    );
  }

  return (
    <>
      {isOrdersLoading && !isTaskModalOpen ? (
        <Spinner isOverflow containerHeight={150} />
      ) : (
        <OrderTable
          onOrderNumberClick={handleOrderNumberClick}
          onCarNameClick={handleCarNameClick}
          carServiceId={activeCarService?.id ?? 0}
          orders={orders}
          handleAddOrderClick={handleAddOrderClick}
          handleViewEditTaskClick={(orderId: number, readOnly: boolean): void =>
            handleViewEditOrderClick(orderId, readOnly)
          }
        />
      )}
      <OrderModalForm
        isOpen={isTaskModalOpen}
        order={order}
        isReadOnly={isOrderModalFormReadOnly}
        isUpdateOrder={isUpdateOrder}
        onClose={handleCloseOrderModal}
        onSubmit={handleOrderModify}
        onEditButtonClick={handleEditButtonClick}
        statuses={statuses}
        employees={employees}
        onOpenCarModal={handleOpenCarModalForm}
        onOpenListCarsModal={handleOpenListCarsModal}
        workFieldAction={handleWorkFieldAction}
        clientSparePartsFieldAction={handleClientSparePartsFieldAction}
        activeCarServiceId={activeCarService?.id ?? 0}
      />
      <CustomerSparePartsModal
        onSaveSpareParts={onSaveClientSpareParts}
        bindSpareParts={
          order ? order?.sparePartsFromClient ?? [] : clientSpareParts
        }
        isVisible={isClientSparePartsOpen}
        onClose={handleClientSparePartCloseModal}
        orderId={order?.id ?? null}
      />
      <CarModalForm
        car={modalSelectedCar}
        isReadOnly={isCarModalFormReadOnly}
        isOpen={isCarModalOpen}
        onCloseCarModal={handleCloseCarModalForm}
        carServiceId={activeCarService?.id ?? 0}
      />
      <ListCarsModalForm
        isOpen={isListCarsModalOpen}
        onClose={handleCloseListCarsModal}
        carServiceId={activeCarService?.id ?? 0}
        onOpenAddCarModal={handleOpenCarModalForm}
      />
      <SelectWorkToOrderModal
        isOpen={isSelectWorkOpen}
        carServiceId={activeCarService?.id ?? 0}
        orderId={order?.id}
        onClose={handleCloseSelectWorkModal}
        onSubmit={handleSelectWorkToOrderSubmit}
      />
    </>
  );
};

export const CarServicesOrdersWithCarServiceTabs = withCarServicesTabs(
  CarServicesOrders,
  true,
  Pages.ORDERS,
  () => tt(`${AppTranslationKey.CAR_SERVICE_ORDER}:title`) ?? '',
);
