import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { SparePartForm } from 'common/types/types';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<SparePartForm>,
  errors: FieldErrors<SparePartForm>,
  disabled: boolean,
};

const NameField: FC<Props> = ({ register, errors, disabled }) => {
  const { t } = useTranslation(AppTranslationKey.SPARE_PARTS);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="sparePartsName" className="col-sm-3 col-form-label">
        { t('name') }
      </CFormLabel>
      <CCol sm={9}>
        <CFormInput
          disabled={ disabled }
          { ...register('sparePartsName',
            {
              required: t('requiredFormField') ?? 'This field is required',
              validate: (value) =>
                !/^\s*$/.test(value) || (t('notOnlySpaceErrorText') ?? 'Поле не може містити тільки пробіли'),
            })
          }
          id="sparePartsName"
          invalid={Boolean(errors.sparePartsName)}
          feedbackInvalid={errors.sparePartsName?.message}
        />
      </CCol>
    </CRow>
  );
};

export { NameField };
