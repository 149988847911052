import { HistoryEmployeeModalKey } from 'common/enums/enums';

export const historyEmployeeModal = {
  uk: {
    [HistoryEmployeeModalKey.TITLE]: 'Історія працевлаштування співробітника',
    [HistoryEmployeeModalKey.REGISTRATION_DATE]: 'Дата прийняття',
    [HistoryEmployeeModalKey.DISMISSAL_DATE]: 'Дата звільнення',
    [HistoryEmployeeModalKey.WORK_PERIOD]: 'Період роботи',
    [HistoryEmployeeModalKey.TOTAL_WORK_PERIOD]: 'Всього',
  },
  en: {
    [HistoryEmployeeModalKey.TITLE]: 'Employment history of the employee',
    [HistoryEmployeeModalKey.REGISTRATION_DATE]: 'Date of employment',
    [HistoryEmployeeModalKey.DISMISSAL_DATE]: 'Dismissal Date',
    [HistoryEmployeeModalKey.WORK_PERIOD]: 'Period of work',
    [HistoryEmployeeModalKey.TOTAL_WORK_PERIOD]: 'Total',
  },
  ru: {
    [HistoryEmployeeModalKey.TITLE]: 'История трудоустройства сотрудника',
    [HistoryEmployeeModalKey.REGISTRATION_DATE]: 'Дата принятия',
    [HistoryEmployeeModalKey.DISMISSAL_DATE]: 'Дата увольнения',
    [HistoryEmployeeModalKey.WORK_PERIOD]: 'Период работы',
    [HistoryEmployeeModalKey.TOTAL_WORK_PERIOD]: 'Всего',
  },
};
