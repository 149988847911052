import { CCol, CFormCheck, CRow } from '@coreui/react-pro';
import { AppTranslationKey, CashDeskKey, CashDeskModalKey } from 'common/enums/enums';
import { AppToolTip, Icon } from 'components/common/common';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { CashDeskFormData } from '../type';
import styles from './style.module.scss';

type Props = {
  disabled: boolean,
  isCompleted: boolean,
  register: UseFormRegister<CashDeskFormData>,
};

const IsCompletedRow: FC<Props> = ({ register, disabled, isCompleted }) => {

  const { t } = useTranslation(AppTranslationKey.CASH_DESK);

  return (
    <CRow className="pt-1">
      <CCol sm={{ span: 10, offset: 2 }} className="align-self-center">
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}>
          <AppToolTip
            tooltipId={'isCompletedRow'}
            isAppTip
            place={'bottom'}
            tooltipContent={!disabled && isCompleted ? t(`${CashDeskKey.MODAL}.${CashDeskModalKey.DISABLE_FIELD_MSG}`) ?? '' : ''}
          >
            <CFormCheck
              {...register('isCompleted')}
              disabled={disabled || isCompleted}
              className="mt-3"
              label={<span>{t(`${CashDeskKey.MODAL}.${CashDeskModalKey.IS_COMPLETED_LABEL}`)}</span>}
            />
          </AppToolTip>
          <AppToolTip
            tooltipId="Logo"
            tooltipHtmlContent={t(`${CashDeskKey.MODAL}.${CashDeskModalKey.IS_COMPLETED_TIP}`) ?? ''}
            place="bottom"
            isAppTip
            styles={{ maxWidth: 300 }}
          >
            <Icon name={'question'} className={styles.iconQuestion} />
          </AppToolTip>
        </div>
      </CCol>
    </CRow>
  );
};

export { IsCompletedRow };
