import {
  currentDateTimeWithZeroMinutes,
} from 'helpers/helpers';

import { CashDeskFormData } from './cash-desk-form-data.type';

const DEFAULT_CASH_DESK_MODAL_PAYLOAD: CashDeskFormData = {
  cashDeskDate : currentDateTimeWithZeroMinutes(),
  cashDeskTime : currentDateTimeWithZeroMinutes(),
  cashDeskTypeId: 1,
  cashDeskPaymentMethodId: 1,
  cashDeskSum: '',
  cashDeskAssignmentId: -1,
  cashDeskInfo: '',
  isCompleted: false,
};

export { DEFAULT_CASH_DESK_MODAL_PAYLOAD };
