import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import { TextArea } from 'components/textarea/text-area';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<EmployeeFormData>
  value: string,
  isDisable: boolean,
};

const AddInfoField: FC<Props> = ({ register, value, isDisable }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="addInfo" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.ADD_INFO }`) }` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <TextArea<EmployeeFormData>
          id="addInfo"
          register={ register }
          limitSymbols={ 1024 }
          rows={3}
          nameFiled="employeeInfo"
          value={ value }
          disabled={ isDisable }
        />
      </CCol>
    </CRow>
  );
};

export { AddInfoField };
