import { AppTranslationKey, SelectComponentKey } from 'common/enums/enums';
import { AsyncSelect } from 'components/common/common';
import { useMemo, useState } from 'hooks/hooks';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';

import { TCommonAsyncSelectProps } from '../interfaces';
import { MenuList } from './menu-list';

interface IProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends TCommonAsyncSelectProps<Option, IsMulti, Group> {
  data: Option[],
  initialData?: Option[],
  otherOption?: Option,
  maxNumberItems?: number,
  placeholder?: string,
}

const SelectWithLocalSearch = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
    data,
    initialData,
    maxNumberItems = 10,
    otherOption,
    placeholder,
    getOptionLabel = (option: Option): string => (option as { label?: unknown }).label as string,
    ...other
  }: IProps<Option, IsMulti, Group>): ReactElement => {
  const { t } = useTranslation(AppTranslationKey.SELECT_COMPONENT);
  const [countAllItems, setCountAllItems] = useState(data.length);
  const defaultOptions = useMemo(() => {
    const defaultData = initialData || data;
    const firstsOptions = defaultData.slice(0, maxNumberItems === -1 ? defaultData.length : maxNumberItems);

    if (otherOption) {
      firstsOptions.unshift(otherOption);
    }

    return firstsOptions;
  }, [data, initialData]);

  const loadOptions = (query: string, callback: (options: Option[]) => void): void => {
    const regStr = `.*${ query }.*`;
    const regEx = new RegExp(regStr, 'i');

    new Promise<Option[]>((resolve): void => {
      const filteredData = data.filter((item): boolean => regEx.test(getOptionLabel(item)));
      const firstsOptions = filteredData.slice(0, maxNumberItems === -1 ? data.length : maxNumberItems);

      if (otherOption) {
        firstsOptions.unshift(otherOption);
      }

      setCountAllItems(filteredData.length);
      resolve(firstsOptions);
    }).then((result) => callback(result));
  };

  return (
    <AsyncSelect<Option, IsMulti, Group>
      defaultOptions={ defaultOptions }
      loadOptions={ loadOptions }
      getOptionLabel={ getOptionLabel }
      noOptionsMessage={ (): string => t(SelectComponentKey.NO_OPTIONS_MESSAGE) as string }
      placeholder={ placeholder !== undefined ? placeholder : t(SelectComponentKey.SEARCH_SELECT_PLACEHOLDER) }
      { ...other }
      components={ {
        MenuList: ({ children, ...others }) => (
          <MenuList
            countAllItems={ countAllItems }
            { ...others }
          >{ children }</MenuList>
        ),
        ...other.components,
      } }
    />
  );
};

export { SelectWithLocalSearch };
