import { StatusBadge } from 'components/common/common';
import { useAppSelector } from 'hooks/hooks';
import { FC } from 'react';

enum Status {
  WORK = 1,
  VACATION = 2,
  SICK_LEAVE = 3,
  DECREE = 4,
  DISMISSED =  5,
}

const MAP_COLORS =  {
  [Status.WORK]: { background: '#008000', font: '#ffffff' },
  [Status.VACATION]: { background: '#3300ff', font: '#ffffff' },
  [Status.SICK_LEAVE]: { background: '#ffb054', font: '#000000' },
  [Status.DECREE]: { background: '#00d1ff', font: '#000000' },
  [Status.DISMISSED]: { background: '#ff0000', font: '#ffffff' },
};

const StatusCell: FC<{ statusId: number }> = ({ statusId }) => {
  const statuses = useAppSelector(({ dictionary }) => dictionary.data?.employeeStatuses || []);
  const currentStatus = statuses.find(({ id }) => id === statusId);

  if (!currentStatus) {
    return <td>-</td>;
  }

  const backgroundColor = MAP_COLORS[currentStatus.id as Status ]?.background || '#ffffff';
  const fontColor = MAP_COLORS[currentStatus.id as Status ]?.font || '#000000';

  return (
    <td>
      <StatusBadge
        color={ backgroundColor }
        title={ currentStatus.employeeStatusName }
        textColor={ fontColor }
      />
    </td>
  );
};

export { StatusCell };
