import { cilCash, cilCreditCard } from '@coreui/icons';
import {
  AppTranslationKey, CashDeskKey,
  LocalStorageKey,
  Pages,
} from 'common/enums/enums';
import { FiltersData, WithCarServicesTabs } from 'common/types/types';
import { FilterContextProvider } from 'contexts';
import { withCarServicesTabs } from 'hoc/hoc';
import { t as t1 } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CashDeskWidget } from './components';
import { CashOrders } from './components/cash-orders';
import { cashOrderFilterInitialData } from './components/cash-orders/common';

const Widgets: FC = () => {
  const { t } = useTranslation([
    AppTranslationKey.CASH_DESK,
    AppTranslationKey.COMMON,
  ]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <CashDeskWidget
        iconName={'dollar'}
        value={`0 ${t('uah', { ns: AppTranslationKey.COMMON })}`}
        title={t(CashDeskKey.WIDGET_TOTAL_MONEY)}
      />
      <CashDeskWidget
        icon={cilCash}
        value={`0 ${t('uah', { ns: AppTranslationKey.COMMON })}`}
        title={t(CashDeskKey.WIDGET_TOTAL_CASH)}
      />
      <CashDeskWidget
        icon={cilCreditCard}
        value={`0 ${t('uah', { ns: AppTranslationKey.COMMON })}`}
        title={t(CashDeskKey.WIDGET_TOTAL_IN_ACCOUNT)}
      />
    </div>
  );
};

const CashDesk: FC<WithCarServicesTabs> = ({ activeCarService }) => {

  return (
    <FilterContextProvider<FiltersData>
      storageKey={ activeCarService?.id ?? 0 }
      storageName={ LocalStorageKey.CASH_ORDER_FILTERS }
      initialValue={ cashOrderFilterInitialData }>
      <CashOrders activeCarService={activeCarService} />
    </FilterContextProvider>
  );
};

export const CashDeskWithCarServicesTabs =
  withCarServicesTabs(
    CashDesk,
    false,
    Pages.CASH_DESK,
    () => t1('mainMenu:cashDesk') ?? '',
    false,
    <Widgets />,
  );
