import { EmployeeTableRow } from 'common/types/types';
import { AppToolTip } from 'components/common/common';
import { FC } from 'react';

import styles from './style.module.scss';

const InfoCell: FC<EmployeeTableRow> = ({ id, info }) => {
  const tooltipId = `${id}_info`;

  if (!info) {
    return <td>-</td>;
  }

  return (
    <td className={ styles.infoCell }>
      <AppToolTip
        tooltipId={tooltipId}
        tooltipContent={info}
      >
        <div className={styles.content}>
          {info}
        </div>
      </AppToolTip>
    </td>
  );
};

export { InfoCell };
