enum ApiPath {
  AUTH = '/oauth',
  USER = '/user',
  USERS = '/users',
  SERVICES = '/services',
  ORDERS = '/requests',
  STOCKS = '/stocks',
  DICTIONARY = '/dictionary',
  REQUESTS = '/requests',
  EMPLOYEES = '/employees',
  CAR_SERVICE = '/carservice',
  WIDGET = '/widgets',
  CARS = '/cars',
  RECOVERY_PASSWORD = '/change_pass',
  SET_PASSWORD = '/set_pass',
  SUPPLIER = '/counterparty',
  SUPPLIERS = '/counterparties',
  CAR_WORK = '/car-works',
  SPARE_PARTS = '/spare-parts',
  SPARE_PARTS_FROM_CLIENT = '/spare-parts-from-client',
  MAIL = '/mail',
  PAYMENTS = '/payments',
  WORK_ORDERS = '/orders',
  SEND_ERROR = '/send-error',
  DOCUMENTATION = '/documentation',
  CONFIDENTIAL_TEXT = '/confidential-text',
  CASH_ORDER = '/cash-order',
  BENEFIT = '/benefit',
  HISTORY = '/history',
}

export { ApiPath };
