import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';
import { AppRoute, AppTranslationKey, DataStatus, LocalStorageKey, PersonalRoute } from 'common/enums/enums';
import { CarServiceDto } from 'common/types/types';
import { NavTabs, NoDataWithAction, Spinner, usePermissionToastContext } from 'components/common/common';
import { Page404 } from 'components/page-404/page-404';
import { getValidClasses } from 'helpers/helpers';
import { useAppSelector, useCarServices, useEffect, useNavigate,useSearchParams  } from 'hooks/hooks';
import { toNumber } from 'lodash';
import React, { ComponentType, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'services/services';

import { Tariffs } from '../../constants/tariffs';
import { getExtendedUserCarService } from './helpers';
import styles from './styles.module.scss';

interface IContentComponent {
  activeCarService: CarServiceDto,
}

interface ICarServiceTabsLayout {
  ContentComponent: ComponentType<IContentComponent>,
  title?: string,
  isShowAllTab?: boolean,
  badgeText?: Record<number, number>,
}

const CarServiceTabsLayout: FC<ICarServiceTabsLayout> = ({
  ContentComponent,
  title = '',
  isShowAllTab = false,
  badgeText,
}) => {
  const [ urlParams ] = useSearchParams();
  const tabId = urlParams.get('tabId');
  const { userId, carServices, isLoading } = useAppSelector(({ auth, carServices }) => ({
    userId: auth.currentUser?.id ?? 0,
    carServices: carServices.userCarServices,
    isLoading: carServices.dataStatus === DataStatus.PENDING,
  }));
  const navigate = useNavigate();
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);
  const { onPermissionAction } = usePermissionToastContext();
  const userCarServices = isShowAllTab ? getExtendedUserCarService(carServices) : carServices;
  const { activeCarService, handleActiveCarServiceChange, getActiveCarServiceById } = useCarServices({ userId });

  const handleTabClick = (id: number): void => {
    const carService = getActiveCarServiceById(userCarServices, id);
    handleActiveCarServiceChange(carService);
  };

  const handleAddCarService = (): void => {
    navigate(`${AppRoute.PERSONAL}/${PersonalRoute.ADD_CAR_SERVICE}`);
  };

  useEffect(() => {
    const storageCarServiceId: string | null = storage.getItem(LocalStorageKey.ACTIVE_CAR_SERVICE_TAB);
    const carServiceTabId: string | null =
      userCarServices.some((it) => it.id === toNumber(storageCarServiceId))
        ? storageCarServiceId : null;

    if (tabId) {
      const carService = getActiveCarServiceById(userCarServices, toNumber(tabId));
      handleActiveCarServiceChange(carService);
    } else if (!carServiceTabId) {
      handleActiveCarServiceChange(userCarServices[0]);
    } else {
      handleActiveCarServiceChange(getActiveCarServiceById(userCarServices, toNumber(carServiceTabId)));
    }
  }, [tabId, carServices]);

  if (isLoading) {
    return <Spinner isOverflow containerHeight={250}/>;
  }

  if (userCarServices.length === 0) {
    return <NoDataWithAction
      title={t('noCarServiceTitle')}
      message={t('noCarServiceText')}
      actionName={t('addCarServiceCaptionButton')}
      callback={onPermissionAction(handleAddCarService, Tariffs.NO_TARIFF)}
    />;
  }

  if (!activeCarService) {
    return <Page404 />;
  }

  return (
    <CCard>
      <CCardHeader className="d-flex flex-column px-lg-0">
        <h5 className={getValidClasses(styles.headerTitle, 'text-dark')}>{title}</h5>
        <div style={{ paddingLeft: 16 }}>
          <NavTabs
            tabs={userCarServices}
            activeTabId={activeCarService.id}
            handleTabClick={handleTabClick}
            badgeText={badgeText}
          />
        </div>
      </CCardHeader>
      <CCardBody style={{ overflowX: 'auto', minHeight: 150 }}>
        <ContentComponent activeCarService={ activeCarService }/>
      </CCardBody>
    </CCard>
  );
};

export { CarServiceTabsLayout };
