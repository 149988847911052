import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalFormMode, ModalFormType } from 'common/enums/enums';

type State = {
  modalFormMode: Record<ModalFormType, ModalFormMode>
};

const initialState: State = {
  modalFormMode: {
    [ModalFormType.ORDER_MODAL]: ModalFormMode.READONLY_MODE,
    [ModalFormType.CAR_MODAL]: ModalFormMode.READONLY_MODE,
    [ModalFormType.WORK_ORDER_MODAL]: ModalFormMode.READONLY_MODE,
    [ModalFormType.CASH_ORDER_MODAL]: ModalFormMode.READONLY_MODE,
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    enabledReadOnlyModalMode: (state, action: PayloadAction<ModalFormType>) => {
      state.modalFormMode = { ...state.modalFormMode,  [action.payload]:ModalFormMode.READONLY_MODE };
    },
    disableReadOnlyModalMode: (state, action: PayloadAction<ModalFormType>) => {
      state.modalFormMode = { ...state.modalFormMode,  [action.payload]:ModalFormMode.EDIT_MODE };
    },
  },
});

const modalReducer = modalSlice.reducer;
const {
  enabledReadOnlyModalMode,
  disableReadOnlyModalMode,
} = modalSlice.actions;

export { disableReadOnlyModalMode, enabledReadOnlyModalMode, modalReducer };
