import { Option } from '@coreui/react-pro/dist/components/multi-select/types';
import { Pages } from 'common/enums/enums';
import { Filters, FiltersStorage } from 'common/types/types';
import { getPayloadByOrderFilters, getPeriodCurrentWeek } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useEffect, useState } from 'hooks/hooks';
import React, { createContext, FC, useContext } from 'react';
import { storage } from 'services/services';
import { carServicesActions, workOrderAction } from 'store/actions';

import { settings } from './filter-settings';

const initialFilters = {
  isToday: false,
  isCurrentWeek: true,
  isCurrentMonth: false,
  statuses: [],
  ...getPeriodCurrentWeek(),
};

interface IUseFilterPanel {
  filters: Filters,
  isInit: boolean,
  getListItems: () => Promise<void>,
  setOrderFilters: (data: Partial<Filters>) => void,
  setIsInit: (value: boolean) => void,
}

interface IUseFilterPanelProps {
  activeCarServiceId: number | undefined,
  page: Pages.ORDERS | Pages.WORK_ORDERS,
}

const useFilterPanel = ({ activeCarServiceId, page }: IUseFilterPanelProps): IUseFilterPanel => {
  const dispatch = useAppDispatch();
  const { storageName, selectorStatuses } = settings[page];
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const statuses = useAppSelector(selectorStatuses);

  const getListItems = async (): Promise<void> => {
    if (!activeCarServiceId) {
      return;
    }

    const payload = getPayloadByOrderFilters(activeCarServiceId, filters);

    switch (page) {
    case Pages.ORDERS:
      await dispatch(carServicesActions.getOrdersByFilters(payload));
      break;
    case Pages.WORK_ORDERS:
      await dispatch(workOrderAction.getWorkOrdersByFilters(payload));
      break;
    }

    const filtersSettings: FiltersStorage = JSON.parse(storage.getItem(storageName) ?? '{}');

    filtersSettings[activeCarServiceId] = filters;
    storage.setItem(storageName, JSON.stringify(filtersSettings));

    setIsReset(false);
  };

  const setOrderFilters = (data: Partial<Filters>): void => {
    setFilters((prev) => ({ ...prev, ...data }));
  };

  const getStatusesForFilter = (): Option[] => {
    return statuses.map(({ id, name }) => ({
      value: id,
      text: name,
      selected: true,
    }));
  };

  const updateStatuses = (statusesFromStorage: Option[]): Option[] => {
    const statusesForFilters = getStatusesForFilter();
    const mapStatuses: Record<number | string, boolean | undefined> = {};

    statusesFromStorage.forEach((filter) => {
      mapStatuses[filter.value] = filter.selected;
    });

    statusesForFilters.forEach((filter) => {
      if (filter.value in mapStatuses) {
        filter.selected = mapStatuses[filter.value];
      }
    });

    return statusesForFilters;
  };

  useEffect(() => {
    if (activeCarServiceId && statuses.length > 0) {
      const filtersSettings: FiltersStorage = JSON.parse(storage.getItem(storageName) ?? '{}');

      if (filtersSettings[activeCarServiceId]) {
        const updatedStatuses = updateStatuses(filtersSettings[activeCarServiceId].statuses);

        setFilters({ ...filtersSettings[activeCarServiceId], statuses: updatedStatuses });
        setIsInit(true);
      } else {
        setFilters(initialFilters);
        setIsReset(true);
      }
    }
  }, [activeCarServiceId, storageName, JSON.stringify(statuses)]);

  useEffect(() => {
    if (filters.statuses.length === 0 && statuses.length > 0 && isReset) {
      setOrderFilters({ statuses: getStatusesForFilter() });
      setIsInit(true);
    }
  }, [JSON.stringify(filters.statuses), JSON.stringify(statuses), isReset]);

  return {
    filters,
    isInit,
    getListItems,
    setOrderFilters,
    setIsInit,
  };
};

const FilterPanelContext = createContext<IUseFilterPanel | null>(null);

const useFilterPanelContext = (): IUseFilterPanel => {
  const context = useContext(FilterPanelContext);

  if (!context) {
    throw new Error('useFilterPanelContext must be used within the FilterPanelContext');
  }

  return context;
};

interface IProps {
  activeCarServiceId: number | undefined,
  page: Pages.ORDERS | Pages.WORK_ORDERS,
  children: React.ReactNode,
}

const FilterPanelContextProvider: FC<IProps> = ({ children, ...otherProps }) => (
  <FilterPanelContext.Provider value={ useFilterPanel(otherProps) }>
    { children }
  </FilterPanelContext.Provider>
);

export { FilterPanelContextProvider, initialFilters, useFilterPanelContext };
