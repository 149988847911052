import { FILTER_DATE_FORMAT } from 'constants/date-formats';
import compareAsc from 'date-fns/compareAsc';
import parse from 'date-fns/parse';

const compareStrDate = (dateStr1: string, dateStr2: string, format: string = FILTER_DATE_FORMAT): number => {
  const date1 = parse(dateStr1, format, new Date());
  const date2 = parse(dateStr2, format, new Date());

  return compareAsc(date1, date2);
};

export { compareStrDate };
