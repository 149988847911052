import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t as tt } from 'i18next';

import { FilterSelect } from './filter-select';

const translate = (field: string): string => {
  return tt(`${ AppTranslationKey.EMPLOYEES_PROFILES }:${EmployeesProfilesKey.COLUMNS}.${ field }`);
};

const getTableColumn = (): TableColumn[] => [
  {
    key: 'fullName',
    label: translate(EmployeesProfilesKey.FULL_NAME),
    filter: true,
    sorter: true,
    _style: { width: '29%', maxWidth: '29%' },
  },
  {
    key: 'position',
    label: translate(EmployeesProfilesKey.POSITION),
    filter: (values, onChange) => (
      <FilterSelect
        values={ values }
        onChange={ onChange }
        isAllSelected
        placeholder={ translate(EmployeesProfilesKey.POSITION) }
      />
    ),
    sorter: true,
    _style: { width: '20%' },
  },
  {
    key: 'phone',
    label: translate(EmployeesProfilesKey.PHONE),
    filter: true,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'info',
    label: translate(EmployeesProfilesKey.ADD_INFO),
    filter: false,
    sorter: false,
    _style: { width: '20%' },
  },
  {
    key: 'status',
    label: translate(EmployeesProfilesKey.STATUS),
    filter: (values, onChange) => (
      <FilterSelect
        values={ values }
        onChange={ onChange }
        placeholder={ translate(EmployeesProfilesKey.STATUS) }
        isAllSelected
        excludeSelected={ ['звільнений', 'fired', 'уволен'] }
      />
    ),
    sorter: true,
    _style: { width: '15%' },
  },
  {
    key: 'showDetails',
    label: '',
    _style: { width: '1%' },
    filter: false,
    sorter: false,
  },
];

export { getTableColumn };
