import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AsyncThunkConfig,
  CashDeskDto,
  CashDeskRequestDto,
  CashDesksGetRequest,
  CashOrderGetByIdRequest,
} from 'common/types/types';

import { ActionType } from './common';

const addCashDesk = createAsyncThunk<
  CashDeskDto,
  CashDeskRequestDto,
  AsyncThunkConfig
>(ActionType.ADD_CASH_ORDER, async(payload, { extra }) => {
  const { cashDeskApi } = extra;

  return cashDeskApi.addCashDesk(payload);
});

const getCashOrdersByCarServiceId = createAsyncThunk<
  CashDeskDto[],
  CashDesksGetRequest,
  AsyncThunkConfig
>(ActionType.GET_CASH_ORDERS, async(payload, { extra }) => {
  const { cashDeskApi } = extra;

  return cashDeskApi.getCashOrdersByCarServiceId(payload);
});

const getCashOrderById = createAsyncThunk<
  CashDeskDto,
  CashOrderGetByIdRequest,
  AsyncThunkConfig
>(ActionType.GET_CASH_ORDERS_BY_ID, async(payload, { extra })=>{
  const { cashDeskApi } = extra;

  return cashDeskApi.getCashOrderById(payload);
} );

const updateCashOrder = createAsyncThunk<
  CashDeskDto,
  CashDeskRequestDto,
  AsyncThunkConfig
>(ActionType.UPDATE_CASH_ORDER, async (payload, { extra }) => {
  const { cashDeskApi } = extra;

  return cashDeskApi.updateCashOrder(payload);
});

export { addCashDesk, getCashOrderById, getCashOrdersByCarServiceId, updateCashOrder };
