import { CButton } from '@coreui/react-pro';
import { AppRoute, AppTranslationKey, EmployeesRoute, LocalStorageKey, SalaryKey } from 'common/enums/enums';
import { SalaryTableRow } from 'common/types/types';
import { AppToolTip } from 'components/common/common';
import { FILTER_DATE_SHORT_MONTH_YEAR } from 'constants/date-formats';
import { useFilterContext } from 'contexts';
import { parse } from 'date-fns';
import { getPeriodByMonth } from 'helpers/helpers';
import { useNavigate } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'services/services';

import styles from './styles.module.scss';

interface INameCellProps extends SalaryTableRow {
  carServiceId: number,
}

const NameCell: FC<INameCellProps> = ({ id, fullName, carServiceId }) => {
  const { t } = useTranslation(AppTranslationKey.SALARY);
  const navigate = useNavigate();
  const idTooltip = `fullNameCell_${ id }`;
  const contentTooltip = t(`${SalaryKey.COLUMNS}.${SalaryKey.FIN_INFO_CELL_TOOLTIP}`, { id });
  const { getValue } = useFilterContext();

  const openFinInfoPage = (): void => {
    const periodDate = getValue('period') as string;
    const value = periodDate ? parse(periodDate, FILTER_DATE_SHORT_MONTH_YEAR, new Date()) : null;
    const savedFilters = JSON.parse(storage.getItem(LocalStorageKey.EMPLOYEE_FIN_INFO_FILTERS) ?? '{}');

    savedFilters[`${ carServiceId }_${ id }`] = {
      ...savedFilters[`${ carServiceId }_${ id }`],
      period: periodDate,
      rangeDate: getPeriodByMonth(value || new Date()),
    };
    storage.setItem(LocalStorageKey.EMPLOYEE_FIN_INFO_FILTERS, JSON.stringify(savedFilters));
    navigate(`${ AppRoute.EMPLOYEES }/${ EmployeesRoute.FIN_INFO }/${ id }?carServiceId=${ carServiceId }`);
  };

  return (
    <td>
      <AppToolTip
        tooltipId={idTooltip}
        tooltipContent={contentTooltip}
        place={'bottom'}
        styles={{  background: '#4c4c4c', color: 'white', opacity: 0.3 }}
      >
        <CButton
          color="link"
          size="sm"
          className={styles.nameLink}
          onClick={ openFinInfoPage }
        >
          { fullName }
        </CButton>
      </AppToolTip>
    </td>
  );
};

export { NameCell };
