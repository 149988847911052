import { LocalStorageKey } from 'common/enums/enums';
import { CarServiceDto, TValue } from 'common/types/types';
import { FILTER_DATE_SHORT_MONTH_YEAR } from 'constants/date-formats';
import { FilterContextProvider } from 'contexts';
import { getFormattedDate } from 'helpers/helpers';
import { FC } from 'react';

import { FilterPanel } from '../filter-panel';
import { SalariesTable } from '../salaries-table';
import { Footer } from '../salaries-table/footer';
import styles from './styles.module.scss';

interface IFilterData extends Record<string, TValue> {
  period: string,
}

interface ISalariesList {
  activeCarService: CarServiceDto,
}

const SalariesList: FC<ISalariesList> = ({ activeCarService }) => {
  return (
    <FilterContextProvider<IFilterData>
      storageKey={activeCarService.id}
      initialValue={ { period: getFormattedDate(new Date(), FILTER_DATE_SHORT_MONTH_YEAR) } }
      storageName={ LocalStorageKey.SALARY_FILTERS }
    >
      <div className={styles.filterContainer}>
        <FilterPanel />
      </div>
      <SalariesTable activeCarServiceId={ activeCarService.id } />
      <Footer />
    </FilterContextProvider>
  );
};

export {
  SalariesList,
};
