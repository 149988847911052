import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey, CashDeskKey, CashDeskModalKey } from 'common/enums/enums';
import { useAppSelector } from 'hooks/hooks';
import { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { CashDeskFormData } from '../type';

type Props = {
  typeId: number,
  disabled: boolean,
  register: UseFormRegister<CashDeskFormData>,
  errors: FieldErrors<CashDeskFormData>,
};

const AssignmentCashDesk: FC<Props> = ({ typeId, disabled, register, errors }) => {

  const { t } = useTranslation([AppTranslationKey.CASH_DESK, AppTranslationKey.COMMON]);

  const { cashOrderAssignmentsIn, cashOrderAssignmentsOut } = useAppSelector(({ dictionary }) => ({
    cashOrderAssignmentsIn: dictionary.data?.cashOrderAssignmentsIn ?? [],
    cashOrderAssignmentsOut: dictionary.data?.cashOrderAssignmentsOut ?? [],
  }));

  const cashDeskAssignments =
    [...cashOrderAssignmentsIn].sort((a, b) => a.listPosition - b.listPosition)
      .concat([...cashOrderAssignmentsOut].sort((a, b) => a.listPosition - b.listPosition));

  return (
    <CRow className="pt-1">
      <CFormLabel htmlFor="cashDeskAssignmentId" className="col-sm-2 col-form-label align-self-center">
        {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.CASH_DESK_ASSIGNMENT}`)}
      </CFormLabel>
      <CCol sm={10} className="align-self-center">
        <CFormSelect
          disabled={disabled}
          { ...register('cashDeskAssignmentId', {
            validate: (value) =>
              +value !== -1 || (t('required', { ns: AppTranslationKey.COMMON }) ?? 'This field is required'),
          })}
          id="cashDeskAssignmentId"
          invalid={Boolean(errors.cashDeskAssignmentId)}
          feedbackInvalid={errors.cashDeskAssignmentId?.message}
        >
          <option key={-1} value={-1}></option>
          {
            cashDeskAssignments
              .filter((it) => it.cashOrderTypeId === +typeId)
              .map(({ cashOrderAssignmentId, cashOrderAssignmentName }) => (
                <option key={cashOrderAssignmentId} value={cashOrderAssignmentId}>
                  {cashOrderAssignmentName}
                </option>
              ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export {  AssignmentCashDesk };
