enum ActionType {
  GET_EMPLOYEES = 'employees/get-employees',
  GET_SHORT_EMPLOYEES = 'employees/get-short-employees',
  CREATE_EMPLOYEE = 'employees/create-employee',
  UPDATE_EMPLOYEE = 'employees/update-employee',
  GET_EMPLOYEE_BY_ID = 'employees/get-employee-by-id',
  GET_HISTORY = 'employees/get-history',
}

export { ActionType };
