import {
  CForm,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro';
import {
  AppTranslationKey,
  CashDeskKey,
  CashDeskModalKey,
  DangerAlertModalMode,
  DataStatus,
  ModalFormType,
  NotificationType,
} from 'common/enums/enums';
import { CashDeskRequestDto } from 'common/types/types';
import { AppToolTip, Spinner } from 'components/common/common';
import { ModalFooter } from 'components/modals/components/components';
import { getFormattedDate } from 'helpers/helpers';
import {
  useAppDispatch,
  useAppSelector,
  useEffect,
  useForm,
  useState,
} from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { appActions, cashDeskAction } from 'store/actions';
import { clearCashOrder } from 'store/cash-desk/reducer';
import { disableReadOnlyModalMode } from 'store/modal/reducer';

import { DangerAlertModal } from '../modals';
import {
  AssignmentCashDesk,
  CalculateMethodCashDeskRow,
  DatetimeCashDeskRow,
  InfoCashDeskRow, IsCompletedRow,
  SumCashDeskRow,
  TypeCashDeskRow,
} from './components';
import { CashDeskFormData, DEFAULT_CASH_DESK_MODAL_PAYLOAD } from './type';

type Props = {
  isOpen: boolean;
  isReadOnly: boolean;
  carServiceId: number | null;
  onCloseModal: () => void;
};

const CashDeskModal: FC<Props> = ({ isOpen, onCloseModal, isReadOnly, carServiceId }) => {
  const {
    control,
    register,
    reset,
    resetField,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<CashDeskFormData>({
    defaultValues: DEFAULT_CASH_DESK_MODAL_PAYLOAD,
  });

  const {
    cashOrder,
    cashOrderLoadStatus,
    cashOrderUpdateStatus,
  } = useAppSelector(({ cashDesk }) => ({
    cashOrder: cashDesk.cashOrder,
    cashOrderLoadStatus: cashDesk.cashOrderLoadStatus,
    cashOrderUpdateStatus: cashDesk.cashOrderUpdateStatus,
  }));

  const { t } = useTranslation(AppTranslationKey.CASH_DESK);
  const [isDangerAlertModalOpen, setIsDangerAlertModalOpen] = useState<boolean>(false);
  const [dataForConfirm, setDataForConfirm] = useState<CashDeskFormData | null>(null);
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector(({ user }) => ({
    userId: user.user?.id,
  }));

  const isUpdating = cashOrderUpdateStatus === DataStatus.PENDING;
  const isLoading = cashOrderLoadStatus === DataStatus.PENDING;

  const cashDeskTypeId = watch('cashDeskTypeId');

  useEffect(() => {
    resetField('cashDeskAssignmentId');
  }, [cashDeskTypeId]);

  useEffect(() => {
    if(cashOrder) {
      reset({
        cashOrderId: cashOrder.cashOrderId,
        cashDeskTime: new Date(cashOrder.createDttm),
        cashDeskDate: new Date(cashOrder.createDttm),
        cashDeskSum: cashOrder.orderValue.toString(),
        cashDeskPaymentMethodId: cashOrder.paymentMethodId,
        cashDeskInfo: cashOrder.dopInfo,
        cashDeskTypeId: cashOrder.cashOrderTypeId,
        cashDeskAssignmentId: cashOrder.cashOrderAssignmentId,
        isCompleted: cashOrder.isCompleted,
      });
    } else {
      reset(DEFAULT_CASH_DESK_MODAL_PAYLOAD);
    }
  }, [cashOrder]);

  useEffect(() => {
    dataForConfirm && setIsDangerAlertModalOpen(true);
  }, [dataForConfirm]);

  const handleSaveCashDeskButtonClick = (data: CashDeskFormData): void=> {
    if (carServiceId !== null) {
      const dateStr = getFormattedDate(
        data.cashDeskDate || new Date(),
        'yyyy-MM-dd',
      );
      const timeStr = getFormattedDate(
        data.cashDeskTime || new Date(),
        'HH:mm',
      );

      const payload: CashDeskRequestDto = {
        cashOrderTypeId: data.cashDeskTypeId,
        cashOrderAssignmentId: data.cashDeskAssignmentId,
        paymentMethodId: data.cashDeskPaymentMethodId,
        carServiceId: carServiceId,
        ownerId: userId ?? 0,
        orderValue: +(data.cashDeskSum ?? 0),
        dopInfo: data.cashDeskInfo,
        createDttm: `${dateStr}T${timeStr}`,
        isCompleted: data.isCompleted,
      };

      if (data.cashOrderId) {
        dispatch(
          cashDeskAction.updateCashOrder({
            ...payload,
            cashOrderId: data.cashOrderId,
          }),
        )
          .unwrap()
          .then(() =>
            dispatch(
              appActions.notify({
                type: NotificationType.SUCCESS,
                message: t(CashDeskKey.SUCCESS_UPDATED),
              }),
            ),
          );
      } else {
        dispatch(cashDeskAction.addCashDesk(payload))
          .unwrap()
          .then(() =>
            dispatch(
              appActions.notify({
                type: NotificationType.SUCCESS,
                message: t(CashDeskKey.SUCCESS_ADDED),
              }),
            ),
          );
      }
      handleCloseModal();
    }
  };

  const onSubmit = (data: CashDeskFormData): void => {

    if (data.isCompleted && !(cashOrder?.isCompleted ?? false) ) {
      setDataForConfirm(data);
    } else {
      handleSaveCashDeskButtonClick(data);
    }
  };

  const handleCloseModal = (): void => {
    dispatch(clearCashOrder());
    onCloseModal();
    reset(DEFAULT_CASH_DESK_MODAL_PAYLOAD);
  };

  const handleEditButtonClick = (): void => {
    dispatch(disableReadOnlyModalMode(ModalFormType.CASH_ORDER_MODAL));
  };

  const handleDangerAlertClose = ():void => {
    setIsDangerAlertModalOpen(false);
    setDataForConfirm(null);
  };

  const handleOnConfirmCancelOrder = ():void => {
    dataForConfirm && handleSaveCashDeskButtonClick(dataForConfirm);
    handleDangerAlertClose();
  };

  return (
    <CModal visible={isOpen} backdrop="static" size="lg" onClose={handleCloseModal}>
      <CModalHeader>
        <CModalTitle>
          {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.TITLE}`)} #{cashOrder?.cashOrderId}
        </CModalTitle>
      </CModalHeader>
      {
        isLoading ? <Spinner isOverflow containerHeight={150}/> :
          <CForm className="form-scroll" onSubmit={handleSubmit(onSubmit)}>
            <CModalBody>
              <DatetimeCashDeskRow
                control={control}
                isCompleted={Boolean(cashOrder?.isCompleted)}
                isReadOnly={isReadOnly}
              />
              <AppToolTip
                tooltipId={'typeCashDeskRow'}
                isAppTip
                tooltipContent={!isReadOnly && Boolean(cashOrder?.isCompleted) ? t(`${CashDeskKey.MODAL}.${CashDeskModalKey.DISABLE_FIELD_MSG}`) ?? '' : ''}
              >
                <TypeCashDeskRow
                  register={register}
                  disabled={isReadOnly || Boolean(cashOrder?.isCompleted)}
                />
              </AppToolTip>
              <AppToolTip
                tooltipId={'calculateMethodCashDeskRow'}
                isAppTip
                place={'bottom'}
                tooltipContent={!isReadOnly && Boolean(cashOrder?.isCompleted) ? t(`${CashDeskKey.MODAL}.${CashDeskModalKey.DISABLE_FIELD_MSG}`) ?? '' : ''}
              >
                <CalculateMethodCashDeskRow
                  register={register}
                  disabled={isReadOnly || Boolean(cashOrder?.isCompleted)}
                />
              </AppToolTip>
              <AppToolTip
                tooltipId={'sumCashDeskRow'}
                isAppTip
                place={'bottom'}
                tooltipContent={!isReadOnly && Boolean(cashOrder?.isCompleted) ? t(`${CashDeskKey.MODAL}.${CashDeskModalKey.DISABLE_FIELD_MSG}`) ?? '' : ''}
              >
                <SumCashDeskRow
                  register={register}
                  disabled={isReadOnly || Boolean(cashOrder?.isCompleted)}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  errors={errors}
                />
              </AppToolTip>
              <IsCompletedRow
                register={register}
                isCompleted={Boolean(cashOrder?.isCompleted)}
                disabled={isReadOnly}
              />
              <AppToolTip
                tooltipId={'assignmentCashDesk'}
                isAppTip
                place={'bottom'}
                tooltipContent={!isReadOnly && Boolean(cashOrder?.isCompleted) ? t(`${CashDeskKey.MODAL}.${CashDeskModalKey.DISABLE_FIELD_MSG}`) ?? '' : ''}
              >
                <AssignmentCashDesk
                  typeId={cashDeskTypeId ?? 0}
                  register={register}
                  disabled={isReadOnly || Boolean(cashOrder?.isCompleted)}
                  errors={errors}
                />
              </AppToolTip>
              <InfoCashDeskRow register={register} disabled={isReadOnly} watch={watch} />
            </CModalBody>
            <ModalFooter
              isReadOnly={isReadOnly}
              isUpdateData={isUpdating}
              onEditButtonClick={handleEditButtonClick}
              onClose={handleCloseModal}
            />
          </CForm>
      }
      <DangerAlertModal
        mode={ DangerAlertModalMode.CONFIRM_PAY_IS_COMPLETED }
        isVisible={isDangerAlertModalOpen}
        onClose={handleDangerAlertClose}
        onConfirm={handleOnConfirmCancelOrder}/>
    </CModal>
  );
};

export { CashDeskModal };
